import styled from 'styled-components';

import colors from 'styles/theme/colors';

const MediaStatus = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	min-height: 144px;
	text-align: center;
	background-color: ${colors.mist};
	padding: 1rem;
	border-radius: 10px;
	&.error {
		align-items: center;
		flex-direction: column;

		p {
			margin: 0;
		}

		.icon {
			margin-bottom: 16px;
			svg path {
				fill: #ff474e;
			}
		}
	}
`;

const Styled = {
	MediaStatus,
};

export default Styled;
