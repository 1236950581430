import { AxiosError } from 'axios';

import { PublisherInviteModel, PublisherPrivilegeModel, UserModel } from 'api-models';
import { Heading } from 'components/Heading';
import EditPublisherName from 'components/Settings/Components/EditPublisherName/EditPublisherName';
import InvitationList from 'components/Settings/Components/InvitationList/InvitationList';
import InviteUsers from 'components/Settings/Components/InviteUsers/InviteUsers';
import LogoAndAdmins from 'components/Settings/Components/LogoAndAdmins/LogoAndAdmins';
import UsersWithRolesList from 'components/Settings/Components/UsersWithRolesList/UsersWithRolesList';
import Styled from 'components/Settings/Settings.style';
import { OrganizationHierarchyRole } from 'components/Settings/types';
import { CREATE_INVITATION, EDIT } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import toast from 'services/Toast';

import { ManageTeamPanelProps } from './types';

const MyCompany = ({ publisher, mutatePublishers, publishers, setPublisher }: ManageTeamPanelProps) => {
	const manager = useInjection<PublisherManager>(PublisherManager);
	const { repository, mutate } = manager.listUsersAndInvitations(publisher.id);

	const invites = repository.findAll<PublisherInviteModel>('publisherInvite') ?? [];
	const userPrivileges: PublisherPrivilegeModel[] = repository.findAll<PublisherPrivilegeModel>('publisherPrivilege') ?? [];

	const { userCan } = useFeaturePermissions(publisher.links);

	function getUserFromPublisherPrivilege(privilege: PublisherPrivilegeModel): UserModel {
		return repository.findOneByRelation<UserModel, PublisherPrivilegeModel>(privilege, 'user') as UserModel;
	}

	const listAdmins = (): UserModel[] => {
		return userPrivileges
			.filter((privilege: PublisherPrivilegeModel) => privilege.attributes.role === OrganizationHierarchyRole.ADMINISTRATOR)
			.map((privilege: PublisherPrivilegeModel) => getUserFromPublisherPrivilege(privilege));
	};

	const changeUserRole = (target: EventTarget & HTMLSelectElement, user: UserModel) => {
		target.disabled = true;
		if (target.value === 'delete') {
			manager
				.deleteUser(publisher.id, user.id)
				.then(() => {
					toast.success('Member removed');
					mutate();
				})
				.catch((e: AxiosError) => {
					console.error(e);
					toast.error(getErrorMessageOnPost('removing the user'));
				})
				.finally(() => {
					target.disabled = false;
				});
		} else {
			manager
				.updateUserPrivilege(publisher.id, user.id, { role: target.value })
				.then(() => {
					toast.success('Role updated');
					mutate();
				})
				.catch((e: AxiosError) => {
					console.error(e);
					toast.error(getErrorMessageOnPost('changing the role'));
				})
				.finally(() => {
					target.disabled = false;
				});
		}
	};

	const deleteInvite = (inviteId: string) => {
		manager
			.deleteInvite(publisher.id, inviteId)
			.then(() => {
				toast.success('Invite deleted');
				mutate();
			})
			.catch((e: AxiosError) => {
				console.error(e);
				toast.error(getErrorMessageOnPost('deleting the invite'));
			})
			.finally(() => {});
	};

	const inviteUsersToClient = (emails: string[], role: OrganizationHierarchyRole): Promise<void> => {
		return manager.inviteUsers(publisher.id, { role: role, emails: emails }).then(() => mutate());
	};

	const deleteImage = () => {
		publisher &&
			manager.deleteLogo(publisher.id).then(() => {
				mutatePublishers();
				toast.success('Logo deleted');
			});
	};

	const uploadLogo = (file: File) => {
		publisher.id &&
			manager.uploadLogo(publisher.id, file).then(() => {
				mutatePublishers();
				toast.success('Logo uploaded');
			});
	};

	return (
		<Styled.Wrapper data-testid='company-section'>
			<EditPublisherName publisher={publisher} canEdit={userCan(EDIT)} publishers={publishers} setPublisher={setPublisher} />
			<div>
				<div style={{ width: '300px', height: 'auto' }}>
					<LogoAndAdmins
						admins={listAdmins()}
						deleteImageFn={deleteImage}
						uploadFn={(file: File) => uploadLogo(file)}
						item={publisher}
						canEdit={userCan(EDIT)}
					/>
				</div>
			</div>
			<Styled.StyledCard>
				<Heading as='h4'>Members</Heading>
				{userCan(CREATE_INVITATION) && (
					<p>
						Share access to your company by inviting members. Members with access can view and be assigned to all of your campaigns. Permissions can be edited
						at any time.
					</p>
				)}
				{userCan(CREATE_INVITATION) && (
					<InviteUsers
						availableRoles={publisher?.attributes.availableRoles}
						roles={userPrivileges}
						invites={invites}
						deleteInvite={(inviteId: string) => deleteInvite(inviteId)}
						getUser={getUserFromPublisherPrivilege}
						changeUserRole={changeUserRole}
						companyName={publisher.attributes.name}
						inviteTitle='Invite user to your team'
						buttonText='Add member'
						drawerTitle='Member invites'
						testId='add-publisher-users'
						inviteFn={(emails, role) => inviteUsersToClient(emails, role)}
					/>
				)}
				<Styled.ListWrapper>
					<UsersWithRolesList
						availableRoles={publisher?.attributes.availableRoles}
						roles={userPrivileges}
						changeUserRole={changeUserRole}
						getUser={getUserFromPublisherPrivilege}
						canEdit={userCan(CREATE_INVITATION)}
					/>
					<InvitationList invites={invites} deleteInvite={deleteInvite} canEdit={userCan(CREATE_INVITATION)} />
				</Styled.ListWrapper>
			</Styled.StyledCard>
		</Styled.Wrapper>
	);
};

export default MyCompany;
