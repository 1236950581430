import styled, { css } from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { devices, devicesMax } from 'styles/variables/media-queries';

import { Mode } from './types';

const darkMode = {
	backgroundColor: 'rgba(77,75,76, 0.9)',
	borderColor: newColors.steel,
	iconColor: newColors.dust,
	labelColor: colors.blueOnBlack,
	ValueColor: newColors.mist,
};

const lightMode = {
	backgroundColor: 'rgba(242,242,242, 0.9)',
	borderColor: newColors.ash,
	iconColor: newColors.slate,
	labelColor: newColors.slate,
	ValueColor: newColors.black,
};

const mode = {
	default: darkMode,
	light: lightMode,
};

const borderStyle = css`
	background-position: right;
	background-size: 1px 5.6px;
	background-repeat: repeat-y;

	@media screen and (${devices.sm}) {
		background-image: linear-gradient(to bottom, ${newColors.dust} 40%, rgba(136, 136, 136, 0) 0%);
	}

	@media screen and (${devicesMax.lg}) {
		&:nth-child(2n) {
			background-image: none;
		}
	}
`;

const MetricItem = styled.div`
	padding: 0 1rem;

	&:not(:last-child) {
		${borderStyle};
	}
`;

const TooltipButton = styled.button`
	background-color: transparent;
	border: none;
	padding: 0;
	height: 18px;
	width: 18px;
	cursor: pointer;
`;

const Label = styled.div`
	display: flex;
	align-items: center;
	font-size: 0.875rem;
	line-height: 1.5;
	margin-bottom: 8px;

	> span {
		display: inline-block;

		& + ${TooltipButton} {
			margin-left: 8px;
		}
	}
`;

const Value = styled.div`
	font-size: 1.1875rem;
	font-weight: 700;
`;

const EmptyState = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	p {
		margin: 0;
	}
`;

const MetricBar = styled.div<{ mode: Mode }>`
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;
	background-color: ${(props) => mode[props.mode].backgroundColor};
	border: 2px solid ${(props) => mode[props.mode].borderColor};
	border-radius: ${borderRadius.m};
	min-height: 92px;
	width: 100%;
	padding: 1rem 1.5rem;
	gap: 16px;

	${Label} {
		color: ${(props) => mode[props.mode].labelColor};
	}

	${Value} {
		color: ${(props) => mode[props.mode].ValueColor};
	}

	${TooltipButton} {
		svg path {
			fill: ${(props) => mode[props.mode].iconColor};
		}
	}

	${EmptyState} > p {
		color: ${(props) => mode[props.mode].ValueColor};
	}

	&.is-empty {
		grid-template-columns: 1fr !important;
	}

	@media screen and (${devices.sm}) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (${devices.lg}) {
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	}
`;

const Styled = {
	MetricBar,
	MetricItem,
	TooltipButton,
	Label,
	Value,
	EmptyState,
};

export default Styled;
