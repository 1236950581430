import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BrandModel, ClientModel, PublisherModel } from 'api-models';
import { ListClientsQuery, ListPublishersQuery } from 'api-queries';
import { HoverButton } from 'components/Button';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import Icon from 'components/Icon';
import Pill from 'components/Pill';
import SelectMenu from 'components/SelectMenu';
import { CreateBrandProps } from 'components/Settings/ClientManagement/types';
import InfoText from 'components/Settings/Components/InfoText/InfoText';
import SettingsStyle from 'components/Settings/Settings.style';
import { SideDrawer } from 'components/SideDrawer';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { useAppSelector } from 'hooks/useUserAppSelector';
import BrandManager from 'services/ApiManager/Brand.manager';
import ClientManager from 'services/ApiManager/Client.manager';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import toast from 'services/Toast';
import colors from 'styles/theme/colors';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

const CreateBrand = ({ client, mutateFn, publisher, newBrandName: newBrand, isOpen, onClose, onCreateCampaign = false, onBrandCreated }: CreateBrandProps) => {
	const user = useAppSelector((state) => state.user);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(isOpen ?? false);
	const [newBrandName, setNewBrandName] = useState<string>(newBrand ?? '');
	const [newClientName, setNewClientName] = useState<string>('');

	const [displayError, setDisplayError] = useState<boolean>(false);
	const [thisPublisher, setThisPublisher] = useState<PublisherModel | undefined>(publisher);
	const [addNewClient, setAddNewClient] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const [thisClient, setThisClient] = useState<ClientModel | undefined>(client);
	const navigate = useNavigate();

	const manager = useInjection<BrandManager>(BrandManager);
	const publisherManager = useInjection<PublisherManager>(PublisherManager);
	const clientManger = useInjection<ClientManager>(ClientManager);

	const { repository: publisherRepo } = publisherManager.listPublishers(
		RequestQueryBuilder.create<ListPublishersQuery>(['logo', 'edit', 'createInvitation', 'createClient', 'deleteLogo']),
	);
	const location = useLocation();
	const publishers = publisherRepo.findAll('publisher') as PublisherModel[];
	const activePublishers = publishers?.filter((publisher) => !publisher.attributes?.disabledAt);
	const userHasRoleOnPublishers = activePublishers?.filter((publisher) => user.permissions.entities?.[publisher.id]?.role);

	const queryBuilder = RequestQueryBuilder.create<ListClientsQuery>(['logo', 'edit', 'delete', 'createInvitation', 'createBrand'])
		.withInclude('brands', ['logo', 'createInvitation', 'edit', 'delete'])
		.withInclude('publisher', ['logo', 'createInvitation', 'edit', 'delete', 'createClient']);
	const { result: clients, repository: clientRepo, mutate: mutateClients } = clientManger.listClients(queryBuilder);

	const getBrandsByClient = (client: ClientModel): BrandModel[] => {
		return client ? clientRepo.findByRelation<BrandModel, ClientModel>(client, 'brands') : [];
	};

	const getFilteredClients = () => {
		if (thisPublisher) {
			return clients?.filter((client: ClientModel) => client.relationships?.publisher?.data?.id === thisPublisher.id) || [];
		}
		return clients || [];
	};

	const handleCreateClient = (inputValue: string) => {
		setAddNewClient(true);
		setNewClientName(inputValue);
	};

	const saveBrand = (clientId: string) => {
		if (newBrandName.length > 0) {
			setDisplayError(false);
			const payload = { name: newBrandName, client: clientId, administrators: [user.id], projectManagers: [], brandManagers: [], dataAnalysts: [] };

			manager
				.create(payload, undefined, {
					mutateFn: mutateFn,
				})
				.then((brand) => {
					setIsModalOpen(false);
					toast.success(`New brand ${newBrandName} created`);
					!onCreateCampaign && navigate(`/settings/brands/${brand.id}`, { state: { invite: true } });
					setNewBrandName('');
					onBrandCreated?.(brand);
				})
				.catch((e: AxiosError) => {
					console.error(e);
					toast.error(getErrorMessageOnPost('creating the brand'));
				})
				.finally(() => {
					setIsSaving(false);
				});
		} else {
			setDisplayError(true);
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewBrandName(e.target.value);
	};

	const handleCreateClientInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewClientName(e.target.value);
		if (e.target.value.length === 0) {
			setAddNewClient(false);
		}
	};

	useEffect(() => {
		if (location.state?.create) {
			setIsModalOpen(true);
		}
	}, [location]);

	const saveClient = () => {
		setIsSaving(true);
		if (thisClient) {
			return saveBrand(thisClient.id);
		}

		if (newClientName === '') {
			setNewClientName(newBrandName);
		}
		setDisplayError(false);
		const payload = {
			name: newClientName || newBrandName,
			administrators: [user.id],
			projectManagers: [],
			brandManagers: [],
			dataAnalysts: [],
		};
		const publisherId = thisPublisher?.id || userHasRoleOnPublishers[0].id;
		return (
			(newClientName || newBrandName) &&
			publisherId &&
			clientManger
				.create(publisherId, payload, undefined, {
					mutateFn: mutateClients,
				})
				.then((client) => {
					saveBrand(client.id);
				})
				.catch((error: AxiosError) => {
					setDisplayError(true);
					toast.error(getErrorMessageOnPost('creating the client'));
					console.error('Error creating list', error);
					setNewClientName('');
					setIsSaving(false);
				})
		);
	};

	const isDuplicateBrandName = (brandName: string, client?: ClientModel): boolean => {
		if (!client || !brandName || newClientName) return false;
		const clientBrands = getBrandsByClient(client);
		return clientBrands.some((brand) => brand.attributes?.name?.toLowerCase() === brandName.toLowerCase());
	};

	useEffect(() => {
		if (thisPublisher) {
			setNewClientName('');
		}
	}, [thisPublisher]);

	useEffect(() => {
		if (isOpen) {
			setIsModalOpen(true);
		}
	}, [isOpen]);

	useEffect(() => {
		setNewBrandName(newBrand ?? '');
	}, [newBrand]);

	useEffect(() => {
		if (!isModalOpen && onClose) {
			onClose();
			setIsSaving(false);
		}
	}, [isModalOpen]);

	useEffect(() => {
		if (!publisher) {
			setThisPublisher(activePublishers[0]);
		}
	}, [activePublishers.length, publisher]);

	return (
		<>
			<HoverButton useAddIcon onClick={() => setIsModalOpen(true)}>
				Add brand {client && `to ${client?.attributes?.name}`}
			</HoverButton>
			<SideDrawer
				sidebarIsOpen={isModalOpen}
				onClose={() => {
					setIsModalOpen(false);
					if (onClose) onClose();
					setIsSaving(false);
				}}
				dataTestId={''}
				title='Brand management'
				isExpandable
				saveButtonText='Save Brand'
				onSave={saveClient}
				isSubmitting={isSaving}
				expandedTitle={
					<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
						Add brand {thisClient && <Pill backgroundColor={colors.paleGreenTint} title={`${thisClient?.attributes.name}`} />}
					</div>
				}
			>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingTop: '1rem' }}>
					{userHasRoleOnPublishers.length > 1 && (
						<Field label='Select organization'>
							<SelectMenu
								placeholder='Select organization'
								onChange={(option) => {
									setThisPublisher(option?.value);
								}}
								options={userHasRoleOnPublishers.map((pub: PublisherModel) => ({
									value: pub,
									label: pub.attributes.name,
								}))}
								value={{ value: thisPublisher, label: thisPublisher?.attributes?.name }}
							/>
						</Field>
					)}
					{addNewClient ? (
						<Field label='Client name'>
							<Input value={newClientName} name='newClientName' placeholder='Name your client' onChange={(e) => handleCreateClientInputChange(e)} />
						</Field>
					) : (
						<Field label='Select client'>
							<SelectMenu
								placeholder='Select client'
								onChange={(option) => {
									setThisClient(option?.value);
								}}
								options={getFilteredClients().map((client: ClientModel) => ({
									value: client,
									label: client.attributes.name,
								}))}
								value={{ value: thisClient, label: thisClient?.attributes.name }}
								noOptionsMessage={({ inputValue }) => (
									<HoverButton useAddIcon onClick={() => handleCreateClient(inputValue)}>
										Create new client "{inputValue}"
									</HoverButton>
								)}
								isSearchable
							/>
						</Field>
					)}
					<Field label='Brand name'>
						<Input value={newBrandName} name='newBrandName' placeholder='Name your brand' onChange={(e) => handleInputChange(e)} />
						{displayError && (
							<SettingsStyle.HelperText>
								<Icon name='alert' size='12' /> <span>You need to enter the name of your brand</span>
							</SettingsStyle.HelperText>
						)}
						<InfoText
							color={isDuplicateBrandName(newBrandName, thisClient) ? colors.butter : colors.paleGreenTint}
							text={
								isDuplicateBrandName(newBrandName, thisClient)
									? `Warning: A brand with the name "${newBrandName}" already exists for this client.`
									: thisPublisher
										? `This brand will be associated with ${addNewClient ? newClientName || 'new client' : thisClient ? thisClient?.attributes.name : ''} ${
												thisPublisher ? `${thisClient ? '(for' : ''} ${thisPublisher.attributes.name} ${thisClient ? ')' : ''}` : ''
											}`
										: activePublishers.length > 1
											? 'Select organization'
											: ''
							}
						/>
					</Field>
				</div>
			</SideDrawer>
		</>
	);
};

export default CreateBrand;
