import styled, { DefaultThemeV2 } from 'styled-components';

import { Accordion } from 'components';
import { StyledCard } from 'components/Settings/Components/ClientDetails/ClientDetails.style';
import colors from 'styles/theme/colors';
import { devices } from 'styles/variables/media-queries';
type T = { theme: DefaultThemeV2 };

export const Card = styled.div`
	display: flex;
	width: 100%;
	background-color: ${colors.mist};
	border-radius: ${(props: T) => props.theme.radius.default};
	padding: 1.5rem;
	align-items: flex-start;
	flex-direction: column;
	gap: 8px;
	@media screen and (${devices.lg}) {
		justify-content: space-between;
		flex-direction: row;
	}
`;

export const StyledAccordion = styled(Accordion)`
	background-color: ${colors.mist};
	border-radius: ${(props: T) => props.theme.radius.default};
	padding: 1.5rem;
	.accordion__title {
		font-weight: 600;
		padding-left: 0;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
	.accordion__header {
		margin-bottom: 0;
	}
	p {
		margin-bottom: 0;
	}
`;

const ListWrapper = styled.div`
	margin-top: 24px;
	max-height: 600px;
	overflow-y: auto;
	width: 100%;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	align-items: center;
	margin-bottom: 32px;
	button {
		width: 100%;
	}
	@media screen and (${devices.lg}) {
		flex-direction: row;
		justify-content: space-between;
		button {
			width: auto;
		}
	}
`;
export default {
	ButtonsWrapper,
	ListWrapper,
	Card,
	StyledCard,
};
