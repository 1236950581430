import { isEqual } from 'lodash';
import { useState } from 'react';

import { PublisherModel } from 'api-models';
import { HoverButton } from 'components/Button/Button';
import Dropdown from 'components/Dropdown';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import InlineEditButton from 'components/InlineEditButton/InlineEditButton';
import Styled from 'components/Settings/Components/EditClientName/EditClientName.style';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import toast from 'services/Toast';

type EditBrandNameProps = {
	publisher: PublisherModel;
	canEdit: boolean;
	publishers: PublisherModel[];
	setPublisher: (publisher: PublisherModel) => void;
};

const EditPublisherName = ({ publisher, canEdit, publishers, setPublisher }: EditBrandNameProps) => {
	const manager = useInjection<PublisherManager>(PublisherManager);

	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [newName, setNewName] = useState<string>('');
	const [showDropdown, setShowDropdown] = useState<boolean>(false);

	const updateClientName = () => {
		const placeholder = structuredClone(publisher);
		placeholder.attributes.name = newName;

		return manager
			.update(publisher.id, { name: newName }, undefined, {
				optimisticModel: placeholder,
			})
			.then(() => {
				setIsEditMode(false);
				!isEqual(publisher.attributes.name, newName) && toast.success(`Name updated`);
				setNewName('');
			})
			.catch(() => {
				toast.error(getErrorMessageOnPost('updating the name'));
			});
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewName(e.target.value);
	};

	const handleOnKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			updateClientName();
		}
		if (e.key === 'Escape') {
			setIsEditMode(false);
			setNewName('');
		}
	};

	const handlePublisherSelect = (selectedPublisher: PublisherModel) => {
		setShowDropdown(false);
		setPublisher(selectedPublisher);
	};

	return (
		<Styled.ClientDetails>
			<Styled.ClientNameWrapper>
				<Styled.Name className='border-bottom'></Styled.Name>
				{!isEditMode ? (
					<>
						<HoverButton id='background-color-button' icon='arrow-down' iconRight onClick={() => setShowDropdown(!showDropdown)}>
							{publisher.attributes?.name}
						</HoverButton>
						<Dropdown open={showDropdown} setOpen={setShowDropdown}>
							<DropdownMenu>
								{publishers?.map((pub) => (
									<DropdownItem key={pub.id} onClick={() => handlePublisherSelect(pub)}>
										{pub.attributes?.name}
									</DropdownItem>
								))}
							</DropdownMenu>
						</Dropdown>
					</>
				) : (
					<Styled.InlineInput
						autoFocus={true}
						value={newName}
						onChange={(e) => handleChange(e)}
						onKeyDown={(e) => handleOnKeyDown(e)}
						data-testid='brand-name-input'
					/>
				)}
			</Styled.ClientNameWrapper>
			{canEdit && (
				<Styled.EditButtonWrapper>
					<InlineEditButton
						label='Edit'
						editMode={isEditMode}
						setEditMode={() => {
							setIsEditMode(true);
							setNewName(publisher.attributes?.name);
						}}
						saveChanges={updateClientName}
					/>
				</Styled.EditButtonWrapper>
			)}
		</Styled.ClientDetails>
	);
};
export default EditPublisherName;
