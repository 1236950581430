import styled from 'styled-components';

import Accordion from 'components/Accordion';
import { detailText, detailItem } from 'components/NewBriefPage/NewBreifPage.style';
import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { PALETTE } from 'styles/variables/original-colors';
import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	margin: 40px 0;
	background-color: ${colors.mainWhite};
	scroll-margin: 320px;
	line-height: 1.5;
`;

const HeaderSubText = styled.div`
	margin-bottom: ${guttersWithRem.m};
`;

const HeaderText = styled.h4`
	font-family: ${typography.BaseFontFamiliy};
	font-weight: 700;
	font-size: 2rem;
	margin-bottom: 24px;
`;

const ContentWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
`;

const Summary = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: ${guttersWithRem.xl};

	& > span {
		font-weight: 600;
	}
`;

const AssignmentCards = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${guttersWithRem.xs};
`;

const AssignmentCard = styled.div`
	width: 100%;
	background-color: ${PALETTE.aquaHaze};
	padding: ${guttersWithRem.xl};
	text-align: left;
	border-radius: ${borderRadius.m};
	margin-bottom: 40px;
	scroll-margin: 200px;
	& > div {
		&.header {
			display: flex;
			font-size: 17px;
			font-weight: 600;
			justify-content: space-between;
			margin-bottom: 24px;

			& > span {
				display: flex;
				align-items: center;
				column-gap: ${guttersWithRem.xs};
				.icon {
					margin-top: 5px;
				}
			}
			div {
				&.assignment-type {
					margin-top: 4px;
				}
			}
		}

		&.title {
			font-size: 1.5rem;
			font-weight: ${({ theme }) => theme.fontWeights.bold};
			margin-bottom: 24px;
		}

		&.description {
			padding-bottom: ${guttersWithRem.s};
			border-bottom: 1px solid ${newColors.mist};

			& > p {
				overflow-wrap: break-word;
			}
		}

		&.details {
			padding: 0.625rem 0 0;
		}
	}
`;

const Dates = styled.div`
	padding: 0.45rem 0.5rem 0.25rem;
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.xs};
	background-color: ${colors.mainWhite};
	border-radius: ${borderRadius.s};
`;

const CustomAccordion = styled(Accordion)`
	.accordion__body {
		margin-top: 16px;
		max-height: unset;
	}
	.accordion__title {
		background-color: ${newColors.oceanBlue};
		color: ${colors.mainWhite};
		padding: 1rem;
		border-radius: ${borderRadius.s};
		font-size: 15px;
		width: fit-content;
	}
`;

const AccordionContent = styled.div`
	line-height: 1.5;
	& > div {
		&.detail {
			margin-bottom: 40px;

			& > div {
				margin-bottom: ${guttersWithRem.xs};
				&.title {
					font-size: 1rem;
					font-weight: 700;
				}

				&.content {
					font-size: 1rem;
					ul {
						padding-left: 1rem;
						li {
							margin: 16px 0;
						}
					}
					div {
						&.copy {
							display: flex;
							align-items: center;
							gap: 4px;
						}
						button {
							margin-left: 8px;
						}
					}
				}
			}
		}
	}
`;

const DeadlineItem = styled.div`
	width: 100%;
	max-width: 18rem;
	padding: ${guttersWithRem.xs};

	& > div {
		display: flex;
		justify-content: space-between;

		& > div {
			&.title {
				font-weight: 600;
			}

			&.date {
				display: flex;
				align-items: center;
			}
		}
	}
`;

const DetailItem = styled(detailItem)`
	margin-bottom: 24px;
`;

const DetailTitle = styled.h4`
	font-weight: ${({ theme }) => theme.fontWeights.semi};
	font-size: 1.25rem;
	margin-bottom: 8px;
`;

const DetailText = styled(detailText)``;

const Styled = {
	Wrapper,
	HeaderSubText,
	HeaderText,
	ContentWrapper,
	Summary,
	AssignmentCard,
	AssignmentCards,
	Dates,
	CustomAccordion,
	AccordionContent,
	DeadlineItem,
	DetailItem,
	DetailTitle,
	DetailText,
};

export default Styled;
