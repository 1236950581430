import styled, { DefaultThemeV2 } from 'styled-components';

import newColors from 'styles/theme/colors';
import { guttersWithRem } from 'styles/variables/gutter';

type P = { theme: DefaultThemeV2 };

const Wrapper = styled.div``;

const OptionWrapper = styled.div`
	display: flex;
	margin-top: ${guttersWithRem.xxs};
	margin-bottom: ${guttersWithRem.xxs};
`;

const radioPadding = `${guttersWithRem.s} ${guttersWithRem.s}`;
const RadioButtonWrapper = styled.div`
	cursor: pointer;
	position: relative;
	margin-top: ${guttersWithRem.xxs};
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	column-gap: ${guttersWithRem.xs};
	padding: ${radioPadding};
	border-radius: ${({ theme }: P) => theme.radius.small};

	&.selected {
		color: ${newColors.oceanBlue};
		label {
			border-bottom: 2px solid ${newColors.oceanBlue};
		}
	}

	& input {
		cursor: pointer;
		position: absolute;
		width: 100%;
		height: 100%;
	}
`;

const BrandsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	column-gap: ${guttersWithRem.xxs};
	row-gap: ${guttersWithRem.xxs};
`;

const brandItemPadding = `${guttersWithRem.xs} ${guttersWithRem.xs}`;
const BrandItem = styled.div`
	display: flex;
	align-items: center;
	column-gap: 8px;
	padding: ${brandItemPadding};
	padding-top: 0.4rem;
	border: 1px solid ${newColors.ash};
	border-radius: ${({ theme }: P) => theme.radius.default};
	background-color: ${newColors.ash};
	color: ${newColors.black};
	cursor: pointer;

	& path {
		fill: ${newColors.black};
	}
	&.exclude {
		border: 1px solid ${newColors.rose};
		background-color: ${newColors.rose};
	}
`;

const EnterLabel = styled.div`
	color: ${newColors.slate};
`;

const IconContainer = styled.div`
	display: flex;
	cursor: pointer;
`;

const ContentAfterContainter = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;

	.icon {
		line-height: 0;
	}
`;

const Styled = {
	Wrapper,
	OptionWrapper,
	RadioButtonWrapper,
	BrandsWrapper,
	BrandItem,
	EnterLabel,
	IconContainer,
	ContentAfterContainter,
};

export default Styled;
