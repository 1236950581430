import { Model } from 'json-api-models';

import { SegmentModel } from 'api-models';
import { Campaign } from 'types/Campaign';
import { DetailItem } from 'views/Campaign/CreateCampaign/types';

export interface CampaignStatistics {
	totalInfluencerAssignments: number;
	completedAssignments: number;
	influencerTargetCount: number;
	influencersJoined: number;
	nextDeadline: string;
	contentWaitingForReview: number;
}

export enum AssignmentReviewStatus {
	REVIEW,
	APPROVED,
	REJECTED,
}

export const CURRENCIES = ['SEK', 'EUR', 'NOK', 'DKK', 'USD', 'AUD'] as const;
export type Currency = (typeof CURRENCIES)[number]; // Eq: 'SEK' | 'EUR' | 'NOK'

export enum ASSIGNMENT_TYPE {
	INSTAGRAM_POST = 'instagram_post',
	INSTAGRAM_STORY = 'instagram_story',
	INSTAGRAM_REEL = 'instagram_reel',
	TIKTOK_VIDEO = 'tiktok_video',
	AFFILIATE_TASK = 'affiliate',
	YOUTUBE_VIDEO = 'youtube_video',
}

export enum NotificationState {
	UNKNOWN = 'unknown',
	CREATED = 'created',
	SENDING = 'sending',
	FAILED = 'failed',
	DELIVERED = 'delivered',
	READ = 'read',
	CLICKED = 'clicked',
}
export interface IAssignmentReview {
	text: string;
	createdAt: Date;
	updatedAt: Date;
	reviewedAt: Date;
	status: AssignmentReviewStatus;
	approvalComment: string;
	waivedDeadline: boolean;
	rejectComment: string;
}

export interface Influencer {
	id: string;
	links: {
		profilePictureUrl: string;
	};
	username: string;
	followersCount: number;
	collabsId?: string;
	user: Model;

	alreadySelected?: boolean;
}

export class AssignmentReview extends Model {
	protected casts = {
		createdAt: Date,
		updatedAt: Date,
		reviewedAt: Date,
		waivedDeadline: Boolean,
	};
}

export type ContentReview = IAssignmentReview & AssignmentReview;

export interface Commission {
	name: string; // Ex: 'Commission 1';
	maxNumberOfInfluencers: number | null; // Ex: 10;
	fixedAmount: number | string; // Ex: 1000000;
	fixedAmountCurrency: string; // Ex: 'SEK';
	agencyFee: number; // Ex: 1000;
	agencyFeeCurrency: Currency; // Ex: 'SEK';
	agencyFeePercentage: number; // Ex: 0.75;
	influencers: string[]; // Ex: ['3c30f4bc-eade-493e-902a-222b62e8a497', '34d6a9b6-2288-4045-9bd9-e4e955971ff2']
	isAffiliate?: boolean;
}

export interface AssignmentDeadline {
	id?: string;
	name: string;
	date: string;
}

export interface AssignmentGroup {
	name: string; // Ex: 'Date 1'
	start: string; // Ex: '2022-07-01'
	end: string; // Ex: '2022-07-31'
	influencerIds: string[]; // Ex: ['2136a9d9-7ed2-46fb-b893-419e2f7edf73', '742e23c7-3db0-4738-8044-b1ea80ac65b5']
	deadlines?: AssignmentDeadline[];
	id?: string; // Exist on copy assignments and needs to be removed
}

export interface Assignment {
	name: string;
	kind: ASSIGNMENT_TYPE | undefined;
	type?: ASSIGNMENT_TYPE | undefined;
	description: string;
	dos: string;
	donts: string;
	cta: string;
	groups: AssignmentGroup[];
}

export interface Product {
	name: string;
	link?: string;
	image?: string;
	description?: string;
	value: number;
	currency: Currency;
	influencers: string[];
	deleteImageUrl: string | undefined;
}

export interface FormProps {
	campaignId: string | number;
	campaign: Campaign;
	canCreate: boolean;
	initialValues?: Model | null;
	isAffiliate?: boolean;
	refresh: () => void;
	allCommissions?: Array<Model>;
}

export interface SidebarFormProps {
	campaignId: string | number;
	isAffiliate?: boolean;
	expand: () => void;
	change: boolean;
	setChange: (change: boolean) => void;
	isExpanded: boolean;
	canEdit: boolean;
	tagSuggestions: {
		hashtags: string[];
		mentions: string[];
	};
	segments: Array<SegmentModel> | undefined;
}

export interface SidebarAssignmentGroup {
	name: string; // Ex: 'Date 1'
	start: string; // Ex: '2022-07-01'
	end: string; // Ex: '2022-07-31'
	influencerIds: string[]; // Ex: ['2136a9d9-7ed2-46fb-b893-419e2f7edf73', '742e23c7-3db0-4738-8044-b1ea80ac65b5']
	deadlines: AssignmentDeadline[];
	id?: string; // Exist on copy assignments and needs to be removed
}

export interface SidebarAssignment {
	name: string;
	kind: string;
	groups: SidebarAssignmentGroup[];
	details: DetailItem[];
	hashtags: string[];
	mentions: string[];
}

export interface SidebarSegment {
	lightColor?: string;
	darkColor?: string;
	icon?: string;
	name: string;
	spots?: number;
	budgetAmount?: number;
	currency?: string;
}

// Custom model because it's not in the generated ones
export type AssignmentDeadlineModel = { type: 'assignmentDeadline'; id: string; attributes: AssignmentDeadline };

export type AllowedType = 'assignment' | 'product' | 'commission' | 'segment';

export type RouteParams = {
	campaignId: string;
	assignmentId?: string;
	productId?: string;
	commissionId?: string;
	segmentId?: string;
	influencerId?: string;
	ownerId?: string;
};
