import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { PublisherModel } from 'api-models';
import { UpdatePublisherPayload } from 'api-payloads';
import { PrimaryButton } from 'components/Button';
import CountrySelector from 'components/Form/Elements/CountrySelector/CountrySelector';
import InputText from 'components/Form/FormikElements/Text';
import Field from 'components/Forms/Field';
import SelectMenu from 'components/SelectMenu';
import Styled from 'components/Settings/Settings.style';
import useInjection from 'hooks/useInjection';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import toast from 'services/Toast';
import Grid from 'styles/grid/grid';

import VatValidation from './Components/VatValidation';

/**
 * BillingForm
 * @returns {JSX.Element}
 */
const Billing = (props: { publisher: PublisherModel; setPublisher: (publisher: PublisherModel) => void; publishers: PublisherModel[] }): JSX.Element => {
	const { publisher } = props;
	const [shouldValidate, setShouldValidate] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const manager = useInjection<PublisherManager>(PublisherManager);

	function handleSubmit(item: PublisherModel, payload: UpdatePublisherPayload) {
		const placeholder = structuredClone(item);
		setIsLoading(true);

		return manager
			.update(item.id, payload, undefined, {
				optimisticModel: placeholder,
			})
			.then(() => {
				toast.success(`Information saved`);
			})
			.catch((error) => {
				console.error('Error saving the publisher', error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Styled.Content>
			<h4>Billing</h4>
			<Grid.Container>
				<Grid.Column lg={7} xs={12}>
					{props.publishers.length > 1 && (
						<Field label='Select organization' className='select-publisher'>
							<SelectMenu
								placeholder='Select organization'
								onChange={(option) => {
									props.setPublisher(option?.value);
								}}
								options={props.publishers.map((publisher: PublisherModel) => {
									return { value: publisher, label: publisher.attributes.name };
								})}
								value={{ value: publisher, label: publisher?.attributes.name }}
							/>
						</Field>
					)}
					<Formik
						initialValues={{
							invoiceEmail: publisher.attributes.invoiceEmail,
							legalName: publisher.attributes.legalName,
							invoiceAddress: publisher.attributes.invoiceAddress,
							invoicePostalCode: publisher.attributes.invoicePostalCode,
							invoiceCity: publisher.attributes.invoiceCity,
							invoiceCountryCode: publisher.attributes.invoiceCountryCode,
							vatNumber: publisher.attributes.vatNumber,
							orgNumber: publisher.attributes.vatNumber?.slice(2),
						}}
						enableReinitialize
						validateOnBlur={shouldValidate}
						validateOnChange={shouldValidate}
						onSubmit={(values) => {
							handleSubmit(publisher, {
								invoiceEmail: values.invoiceEmail,
								invoiceCountryCode: values.invoiceCountryCode,
								vatNumber: values.vatNumber,
							});
						}}
						validationSchema={Yup.object().shape({
							invoiceEmail: Yup.string().email('Invalid email').required('Required'),
							invoiceCountryCode: Yup.string().required('Required'),
							vatNumber: Yup.string(),
						})}
					>
						{({ setFieldValue, values, dirty }) => (
							<Form>
								<InputText label='Billing email' type='email' id='invoiceEmail' name='invoiceEmail' placeholder='you@collabs.app' />
								<CountrySelector name='invoiceCountryCode' />
								<VatValidation setFieldValue={setFieldValue} values={values} />
								<Styled.ButtonGroup>
									<PrimaryButton
										isLoading={isLoading}
										type='submit'
										disabled={!dirty}
										data-testid='submit-btn'
										onClick={() => {
											if (!shouldValidate) {
												setShouldValidate(true);
											}
										}}
									>
										Save
									</PrimaryButton>
								</Styled.ButtonGroup>
							</Form>
						)}
					</Formik>
				</Grid.Column>
			</Grid.Container>
		</Styled.Content>
	);
};

export default Billing;
