import { Link } from 'react-router-dom';
import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';

const AvatarsContainer = styled.div`
	display: flex;
	align-items: center;
`;
const AvatarsStyleWrapper = styled.div`
	margin-right: -32px;
	&:last-child {
		margin-right: 0;
	}
`;

const InfluencerCount = styled.div`
	margin-left: 40px;
`;

const IconContainer = styled.div`
	margin: 0 8px;
	&.action {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		.icon {
			margin-top: 2px;
		}
		&:hover {
			background-color: ${newColors.mist};
		}
	}
`;

const Item = styled.div`
	display: flex;
	align-items: center;
	font-size: 0.875rem;
	font-weight: 600;
	height: 45px;
	&.justify-end {
		justify-content: flex-end;
	}
`;

const SpinnerContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	button {
		width: 171px;
	}
`;

const BreadCrumbs = styled.div`
	display: flex;
	align-items: center;
	font-size: 0.875rem;
	line-height: 2;
	@media screen and (${devices.md}) {
		font-size: 1.2rem;
	}
	font-weight: 600;
	a {
		width: fit-content;
		white-space: nowrap;
		color: ${colors.mainBlack} !important;
		border-bottom: 2px solid ${colors.mainBlack} !important;
	}
	div {
		&.title {
			width: fit-content;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: ${colors.mainBlack};
		}
	}
	a:visited {
		color: ${colors.mainBlack} !important;
	}
`;

const Divider = styled.div`
	margin: 0 8px;
`;

const ArrowContainer = styled.div`
	display: none;
	@media screen and (${devices.md}) {
		display: flex;
		background-color: ${colors.mainBlack};
		border-radius: ${borderRadius.s};
		width: 20px;
		height: 20px;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		.icon {
			margin-left: 1px;
			path {
				fill: ${colors.mainWhite};
			}
		}
	}
`;

const Name = styled.div`
	margin-left: 8px;
	width: 50%;
	max-width: 200px;
	line-height: 1.3;
	@media screen and (${devices.md}) {
		max-width: 500px;
	}
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const InputWrapper = styled.div`
	margin-left: 8px;
	width: 50%;
	max-width: 200px;
	line-height: 1.3;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 5px;

	@media screen and (${devices.md}) {
		max-width: 500px;
	}
	form {
		width: 100%;
	}
	input {
		max-height: 30px !important;
		width: 100%;
		font-weight: 400;
		&:focus {
			outline: none !important;
		}
	}
	form > div {
		margin-bottom: 0;
	}
`;

const IconSection = styled.div`
	display: none;
	.icon {
		transition: opacity 0.2s ease-in;
		opacity: 0;
	}
	@media screen and (${devices.md}) {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: flex-end;
		&.displayIcons {
			.icon {
				opacity: 1;
			}
		}
	}
`;

const BreadCrumbsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
	width: 100%;
	margin-top: 24px;
	button {
		width: fit-content;
	}

	@media screen and (${devices.md}) {
		margin-top: 0;
	}
`;

const Back = styled(Link)`
	width: fit-content;
	white-space: nowrap;
	font-size: 0.875rem;
	font-weight: 600;
	display: flex;
	align-items: center;
	color: ${colors.mainBlack} !important;
	&:active,
	&:visited {
		color: ${colors.mainBlack} !important;
	}
	.icon {
		margin-right: 8px;
	}
`;

const Options = styled.div`
	display: flex;
`;

const Icons = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 16px;
`;

const EmptyDiv = styled.div`
	width: 32px;
`;

const Styled = {
	InputWrapper,
	AvatarsContainer,
	AvatarsStyleWrapper,
	InfluencerCount,
	IconContainer,
	Item,
	SpinnerContainer,
	ButtonContainer,
	BreadCrumbs,
	Divider,
	ArrowContainer,
	Name,
	IconSection,
	BreadCrumbsContainer,
	Back,
	Options,
	Icons,
	EmptyDiv,
};

export default Styled;
