import styled, { DefaultThemeV2 } from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';

type P = { theme: DefaultThemeV2 };

const Wrapper = styled.div`
	padding-bottom: 5rem;
`;

const AddFrameContainer = styled.div`
	margin-bottom: 16px;
`;

const AddFrameContainerInner = styled.div`
	border-radius: 10px;
	height: 80px;
`;

const IconContainer = styled.div`
	width: 28px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: ${borderRadius.s};
	background-color: ${newColors.ash};
`;

const ClickArea = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	font-size: 0.875rem;
	border-radius: 10px;
	text-align: center;
	height: 100%;
	transition:
		background-color 200ms ease-in-out,
		color 200ms ease-in-out,
		border-color 200ms ease-in-out;
	cursor: pointer;

	.icon path {
		fill: ${newColors.slate};
		transition:
			fill 200ms ease-in-out,
			stroke 200ms ease-in-out;

		&.cross {
			stroke: ${newColors.slate};
		}
	}

	&:hover {
		background-color: ${newColors.ash};
		${IconContainer} {
			background-color: ${newColors.snow};
		}
	}
`;

const CommentsContainer = styled.div`
	padding-bottom: 5rem;
	scroll-padding-top: 200px;

	border-radius: 4px;
	animation: pulse 0.8s ease-in-out;
`;

const InvisibleInput = styled.input`
	display: none;
`;

const TitleWrapper = styled.div`
	margin-top: 40px;
	margin-bottom: 8px;
	div {
		font-weight: 600;
	}
	p {
		margin: 0;
		color: ${({ theme }: P) => theme.colors.placeholder};
		font-size: 0.875rem;
	}
`;

const Styled = {
	TitleWrapper,
	CommentsContainer,
	Wrapper,
	AddFrameContainer,
	AddFrameContainerInner,
	InvisibleInput,
	ClickArea,
	IconContainer,
};

export default Styled;
