import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { breakpoints } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	overflow: hidden;
	padding: 0;
	width: 100%;
`;

const ListWrapper = styled.div`
	padding-bottom: 4rem;
	height: 70vh;
	overflow-y: scroll;
`;

const SpinnerWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const InputWrapper = styled.div`
	padding: 0.5rem;
`;

const FilterTabsWrapper = styled.div``;

const FilterTabs = styled.div`
	padding: 0.9rem 1rem;
	height: 44px;
	width: 100%;
	border-radius: ${borderRadius.m};
	border: 1px solid ${newColors.silver};
	cursor: pointer;
	font-size: 1rem;
	span {
		color: ${newColors.dust};
		font-weight: 600;
	}
	&.selected {
		background-color: ${colors.mainBlack};
		color: ${colors.mainWhite};
	}
`;

const Divider = styled.div`
	margin-left: 60px;
	height: 4px;
	@media (max-width: ${breakpoints.sm}) {
		border-bottom: 1px solid ${colors.contentManagement.campaignList.listItem.borderColor};
	}
`;

const NoResults = styled.li`
	padding: 1rem;
	text-align: center;
	.value {
		font-weight: 500;
	}
`;

const NoResultsText = styled.div`
	padding-top: 1rem;
	max-width: 100%;
	overflow-wrap: break-word;
`;

const Styled = {
	Wrapper,
	ListWrapper,
	SpinnerWrapper,
	InputWrapper,
	FilterTabsWrapper,
	FilterTabs,
	Divider,
	NoResults,
	NoResultsText,
};

export default Styled;
