import { FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { HoverButton, PrimaryButton } from 'components/Button';
import { Heading } from 'components/Heading';
import InvitationList from 'components/Settings/Components/InvitationList/InvitationList';
import InviteTeamMembers from 'components/Settings/Components/InviteTeamMembers/InviteTeamMembers';
import UsersWithRolesList from 'components/Settings/Components/UsersWithRolesList/UsersWithRolesList';
import { OrganizationHierarchyRole } from 'components/Settings/types';
import { SideDrawer } from 'components/SideDrawer';
import colors from 'styles/theme/colors';

import Styled from './InviteUsers.style';
import { InviteUsersProps } from './types';

const InviteUsers = ({
	drawerTitle,
	testId,
	inviteTitle,
	inviteFn,
	buttonText,
	companyName,
	roles,
	changeUserRole,
	invites,
	getUser,
	top,
	deleteInvite,
	availableRoles,
}: InviteUsersProps) => {
	const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);
	const location = useLocation();

	const formRef = useRef<FormikProps<{ email: string; role: OrganizationHierarchyRole }> | null>(null);

	const handleSubmit = () => {
		if (formRef.current) {
			formRef.current.handleSubmit();
			setTimeout(() => setSidebarIsOpen(false), 0);
		}
	};

	useEffect(() => {
		if (location?.state?.invite && !top) {
			setSidebarIsOpen(true);
		}
	}, [location.state?.invite, top]);

	return (
		<>
			{top ? (
				<PrimaryButton
					width='150px'
					onClick={() => {
						setSidebarIsOpen(true);
					}}
				>
					Add member
				</PrimaryButton>
			) : (
				<HoverButton
					style={{ backgroundColor: colors.ash }}
					useAddIcon
					onClick={() => {
						setSidebarIsOpen(true);
					}}
				>
					{buttonText}
				</HoverButton>
			)}
			<SideDrawer
				isExpandable
				title={drawerTitle}
				expandedTitle={`Add member to ${companyName ?? 'this company'}`}
				sidebarIsOpen={sidebarIsOpen}
				saveButtonText='Add member'
				onClose={() => {
					setSidebarIsOpen(false);
					if (formRef.current) {
						setTimeout(() => formRef.current?.handleReset(), 0);
					}
				}}
				dataTestId={testId}
				onSave={handleSubmit}
			>
				<>
					<InviteTeamMembers
						availableRoles={availableRoles}
						formRef={formRef}
						text={inviteTitle}
						inviteFn={inviteFn}
						keepDrawerOpen={() => setSidebarIsOpen(true)}
					/>
					<Styled.ScrollDiv data-testid='invited-section'>
						{Array.isArray(roles) && roles.length > 0 && (
							<>
								<Heading as='h5'>Members with access</Heading>
								<UsersWithRolesList availableRoles={availableRoles} roles={roles} changeUserRole={changeUserRole} getUser={getUser} canEdit={false} />
							</>
						)}
						{Array.isArray(invites) && invites.length > 0 && <InvitationList invites={invites} deleteInvite={deleteInvite} canEdit />}
					</Styled.ScrollDiv>
				</>
			</SideDrawer>
		</>
	);
};

export default InviteUsers;
