import { css } from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';

import { InputType } from './types';

const getFieldPadding = (size: string, smallIcon?: boolean): string => {
	if (smallIcon) {
		return '3rem';
	}
	return size === 'md' ? '2.875rem' : '3.5rem';
};

export const InputField = css<InputType>`
	background-color: ${({ theme }) => theme.InputField.background};
	border: ${({ theme }) => theme.InputField.border};
	padding: ${(props) => (props.size === 'md' ? '0.625rem 1rem' : '1.125rem 1rem 1rem 1rem')};
	width: 100%;
	font-size: 1rem;
	transition: box-shadow 0.1s ease-in-out;
	border-radius: ${({ theme }) => theme.InputField.borderRadius};
	outline: 0;

	&:hover:not(:disabled) {
		border: ${({ theme }) => theme.InputField.hover.border};
	}
	&:focus:not(:disabled) {
		box-shadow: 0 0 3px 0 #3a71e3;
	}

	&::placeholder {
		color: ${({ theme }) => theme.InputField.placeholderColor};
	}

	&[type='password']:not(:placeholder-shown) {
		font-family: Verdana;
		letter-spacing: 0.125em;
	}

	&[disabled] {
		border-color: ${({ theme }) => theme.InputField.disabled.borderColor};

		&,
		&::placeholder {
			color: ${({ theme }) => theme.InputField.disabled.placeholderColor};
		}
	}
`;

export const InputFieldV2 = css<InputType>`
	background-color: ${colors.mainWhite}; // @todo replace with theme when we redesign stuff.
	border: none;
	padding: ${(props) => (props.size === 'md' ? '0.625rem 1rem' : '1.125rem 1rem 1rem 1rem')};
	width: 100%;
	font-size: 1rem;
	transition: box-shadow 0.1s ease-in-out;
	border-radius: 4px;
	outline: 0;

	&:focus {
		outline: none;
	}

	&[type='password']:not(:placeholder-shown) {
		font-family: Verdana;
		letter-spacing: 0.125em;
	}

	&[disabled] {
		border-color: ${({ theme }) => theme.InputField.disabled.borderColor};

		&,
		&::placeholder {
			color: ${newColors.slate}; // @todo replace with theme when we redesign stuff.
			font-size: 0.875rem;
		}
	}
`;

export const InputFieldContainerWithIconV2 = css<InputType>`
	position: relative;
	display: ${(props) => (props.group ? 'flex' : 'block')};
	align-items: ${(props) => (props.group ? 'center' : '')};
	border: 1px solid ${newColors.ash}; // @todo replace with theme when we redesign stuff.
	border-radius: ${borderRadius.m};

	.icon {
		margin: 0 6px;
	}

	> input {
		padding-left: ${(props) => (props.iconPosition === 'left' ? (props.group ? getFieldPadding(props.size || 'lg', props.smallIcon) : '') : '')};
		padding-right: ${(props) => (props.iconPosition === 'right' ? (props.group ? getFieldPadding(props.size || 'lg') : '') : '')};
	}
`;

export const InputFieldContainer = css<InputType>`
	position: relative;
	display: ${(props) => (props.group ? 'flex' : 'block')};

	> input {
		padding-left: ${(props) => (props.iconPosition === 'left' ? (props.group ? getFieldPadding(props.size || 'lg', props.smallIcon) : '') : '')};
		padding-right: ${(props) => (props.iconPosition === 'right' ? (props.group ? getFieldPadding(props.size || 'lg') : '') : '')};
	}
`;

export const InputFieldTooltip = css`
	position: absolute;
	top: -28px;
	right: 0;
	cursor: pointer;
`;

export const InputFieldIcon = css<InputType>`
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	left: ${(props) => (props.iconPosition === 'left' ? '0' : 'auto')};
	right: ${(props) => (props.iconPosition === 'right' ? (props.hasAction ? '32px' : '0') : 'auto')};
	display: flex;
	padding-left: 0.5rem;

	i {
		display: flex;
		align-items: center;
	}
`;
