import { AxiosError } from 'axios';
import { FormikProps, FormikValues } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PublisherModel } from 'api-models';
import { ViewPublisherQuery } from 'api-queries';
import { SecondaryButton } from 'components/Button';
import Styled from 'components/LoadingSpinner/LoadingSpinner.style';
import { SideDrawer } from 'components/SideDrawer';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import GenericManager from 'services/ApiManager/Generic.manager';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import { MutateFn } from 'services/ApiManager/types';
import toast from 'services/Toast';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';
import PublisherForm from 'views/admin/Components/Publishers/Components/PublisherForm/PublisherForm';
import { PublisherType } from 'views/admin/Components/Publishers/Components/PublisherOptions/PublisherOptions';

const HandlePublisher = (props: { mutatePublishers: MutateFn; setEditMode: (editMode: boolean) => void }) => {
	const [isSaving, setIsSaving] = useState(false);
	const [displayAreYouSure, setDisplayAreYouSure] = useState(false);

	const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
	const [initialValues, setInitialValues] = useState<{
		name: string;
		email: string;
		kind: PublisherType;
		mediaAgencyFeePercentage: number;
		collabsCommissionPercentage: null | number;
	}>({
		name: '',
		email: '',
		kind: PublisherType.AGENCY,
		mediaAgencyFeePercentage: 0,
		collabsCommissionPercentage: null,
	});

	const manager = useInjection<PublisherManager>(PublisherManager);
	const genericManager = useInjection<GenericManager>(GenericManager);

	const formRef = useRef<FormikProps<{
		name: string;
		email: string;
		kind: PublisherType;
		mediaAgencyFeePercentage: number;
		collabsCommissionPercentage: null | number;
	}> | null>(null);
	const { publisherId } = useParams();

	const { repository, isLoading, mutate } = manager.view(
		publisherId!,
		RequestQueryBuilder.create<ViewPublisherQuery>(['logo', 'edit', 'logo', 'createPublisher', 'deleteLogo', 'disable'])
			.withInclude('clients', ['logo'])
			.withInclude('invites'),
	);

	const publisher = repository.find<PublisherModel>('publisher', publisherId!);

	const createPublisher = () => {
		if (formRef.current) {
			formRef.current.handleSubmit();
		}
	};

	const disablePublisher = () => {
		setIsSaving(true);
		publisher?.links?.disable
			? genericManager
					.update(publisher?.links?.disable, { disable: true })
					.then(() => {
						mutate();
						props.mutatePublishers();
						toast.success('Publisher disabled');
					})
					.finally(() => {
						setIsSaving(false);
						setDisplayAreYouSure(false);
					})
			: toast.error("Publisher can't be disabled, contact Jon");
	};

	const savePublisher = (values: FormikValues) => {
		setValidateAfterSubmit(true);
		setIsSaving(true);
		const payload = {
			name: values.name,
			kind: values.kind,
			mediaAgencyFeePercentage: parseFloat(values.mediaAgencyFeePercentage),
			collabsCommissionPercentage: parseFloat(values.collabsCommissionPercentage),
		};
		return (
			publisherId &&
			manager
				.update(publisherId, payload)
				.then(() => {
					mutate();
					toast.success(`Publisher updated`);
					props.setEditMode(false);
					setValidateAfterSubmit(false);
					props.setEditMode(false);
					setIsSaving(false);
				})
				.catch((error: AxiosError) => {
					toast.error(getErrorMessageOnPost('updating the publisher'));
					console.error('Error update publisher', error);
					setValidateAfterSubmit(false);
					setIsSaving(false);
				})
		);
	};

	useEffect(() => {
		publisher &&
			setInitialValues({
				name: publisher?.attributes.name,
				email: '',
				kind: publisher.attributes.kind as PublisherType,
				mediaAgencyFeePercentage: publisher.attributes.mediaAgencyFeePercentage ?? 0,
				collabsCommissionPercentage: publisher.attributes.collabsCommissionPercentage,
			});
	}, [publisher]);

	return (
		<SideDrawer
			sidebarIsOpen={true}
			canOnlyCloseModal={publisher?.attributes.disabledAt ? true : false}
			expandedTitle={
				<Styled.DrawerTitle className={displayAreYouSure ? 'sure' : ''}>
					<div>
						<span>{publisher?.attributes.disabledAt ? `${publisher?.attributes.name} disabled` : publisher?.attributes.name}</span>
						{!publisher?.attributes.disabledAt && (
							<div className='disable' onClick={() => setDisplayAreYouSure(true)}>
								Disable
							</div>
						)}
					</div>
					{displayAreYouSure && (
						<Styled.AreYouSure>
							Once disabled, you cannot enable this account again, so please be sure.
							<SecondaryButton onClick={() => disablePublisher()} isLoading>
								Yes, disable
							</SecondaryButton>
						</Styled.AreYouSure>
					)}
				</Styled.DrawerTitle>
			}
			onClose={() => {
				props.setEditMode(false);
			}}
			dataTestId={''}
			title='Publisher management'
			isExpandable
			saveButtonText='Save'
			onSave={createPublisher}
			isSubmitting={isSaving}
		>
			<PublisherForm
				disabled={publisher?.attributes.disabledAt ? true : false}
				savePublisher={savePublisher}
				initialValues={initialValues}
				formRef={formRef}
				validateAfterSubmit={validateAfterSubmit}
				isLoading={isLoading}
				publisher={publisher}
				mutate={mutate}
			/>
		</SideDrawer>
	);
};

export default HandlePublisher;
