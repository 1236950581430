import styled from 'styled-components';

import Grid from 'styles/grid/grid';
import colors from 'styles/theme/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	margin-bottom: 80px;
	margin-top: 40px;
`;

const HeadingWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	column-gap: ${guttersWithRem.xs};

	font-size: ${typography.headings.h5.fontSize};
	font-weight: ${typography.headings.h5.fontWeight};
	line-height: 1;

	margin-bottom: ${guttersWithRem.xl};

	& > i {
		line-height: 1;
	}
`;

const InvitationsWrapper = styled(Grid.Container)`
	margin-bottom: ${guttersWithRem.xxl};
`;

const BottomWrapper = styled.div`
	width: 100%;
	line-height: 1;
`;

const SeeAll = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	&:before,
	&:after {
		content: ' ';
		flex-grow: 1;
		height: 1px;
		background-color: ${colors.black};
	}

	&:before {
		margin-right: ${guttersWithRem.m};
	}

	&:after {
		margin-left: ${guttersWithRem.m};
	}
	cursor: pointer;
`;

const SpinnerWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
`;

const Styled = {
	Wrapper,
	HeadingWrapper,
	InvitationsWrapper,
	BottomWrapper,
	SeeAll,
	SpinnerWrapper,
};

export default Styled;
