import styled from 'styled-components';

import colors from 'styles/theme/colors';
import { devices } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	span {
		overflow-y: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

interface Avatar {
	img?: string | undefined;
}

const Avatar = styled.div<Avatar>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background: url(${(props) => props.img ?? ''});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: ${colors.mist};
	border-radius: 250px;
	width: 48px;
	height: 48px;

	&.dark {
		background-color: ${colors.ash};
	}

	&.small {
		width: 24px;
		height: 24px;
		> span {
			font-size: 0.75rem;
		}
	}

	&.medium {
		width: 32px;
		height: 32px;
		> span {
			font-size: 0.8rem;
		}
	}

	> span {
		font-size: 0.938rem;
	}
`;

const fullNameSpan = styled.span`
	font-size: 0.875rem;
	font-weight: 600;
	letter-spacing: 0em;
	line-height: 1.6;
	color: ${colors.black};
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	@media screen and (${devices.lg}) {
		max-width: 100%;
	}
`;

const RoleSpan = styled.span`
	font-size: 0.875rem;
	letter-spacing: 0em;
	color: ${colors.oceanBlue};
	height: 20px;
`;

const NameWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 1rem 0;
	margin-left: 16px;
	&.displayBorder {
		border-top: 1px solid ${colors.mist};
		border-bottom: 1px solid ${colors.mist};
	}
	&.displayBottomBorder {
		border-bottom: 1px solid ${colors.mist};
	}
	&.medium {
		padding: 0.5rem 0;
		${fullNameSpan} {
		}
		${RoleSpan} {
		}
	}
	&.small {
		padding: 0.625rem 0;
		${fullNameSpan} {
			font-size: 0.75rem;
		}
		${RoleSpan} {
			font-size: 0.75rem;
		}
	}
`;

const Styled = {
	Wrapper,
	Avatar,
	fullNameSpan,
	RoleSpan,
	NameWrapper,
};

export default Styled;
