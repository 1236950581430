import styled from 'styled-components';

import { InputField, InputFieldContainer } from 'styles/formElements/input';
import { InputType } from 'styles/formElements/types';
import { Table, TableBody, TableHeader, Th } from 'styles/table';
import newColors from 'styles/theme/colors';
import { CTALink } from 'styles/utils/CTALink';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { devices } from 'styles/variables/media-queries';

interface IFolderListProps {
	isOpen?: boolean;
}
const Wrapper = styled.div`
	width: 100%;
`;

export const Content = styled.div`
	width: 100%;
`;

export const Container = styled.div`
	display: grid;
	column-gap: 1rem;
	grid-template-columns: 2fr 4fr;
`;

export const FolderList = styled.div<IFolderListProps>`
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 1.25rem 0.75rem;
	min-width: 400px;
	border-bottom: 1px black solid;
	cursor: pointer;
	background-color: ${(props) => props.isOpen && newColors.dust};
	color: ${(props) => props.isOpen && newColors.mist};
	fill: ${(props) => props.isOpen && newColors.mist};
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 16px;
	width: 100%;
	button {
		width: 100%;
		font-size: 1rem;
		span {
			display: flex;
			align-items: center;
			gap: 8px;
			.icon {
				margin-bottom: 2px;
			}
		}
	}
	@media screen and (${devices.md}) {
		justify-content: end;
		button {
			width: fit-content;
			font-size: 1.2rem;
		}
	}
`;

export const ActionContainer = styled.div``;

export const BackLinkContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 24px;

	.icon {
		line-height: 0;
	}
`;

export const BackLink = styled.span`
	font-size: 1.25rem;
	font-weight: 700;
	cursor: pointer;
`;

export const ListTable = styled.table`
	${Table};

	.list-table__new-row {
		opacity: 0;
		visibility: hidden;
		transition:
			opacity 0.2s ease-in,
			visibility 0.2s ease-in;

		&--visible {
			opacity: 1;
			visibility: visible;
		}
	}
`;

export const ListTableHead = styled.thead`
	${TableHeader};

	> tr {
		border-top: none;
		border-bottom: 2px solid ${colors.listsPage.tableHeadRowBorderColor};

		> th:first-child {
			width: 16px;
		}
	}
`;

export const ListTableTh = styled.th`
	${Th};
`;

export const ListTableBody = styled.tbody`
	${TableBody};
`;

export const Input = styled.input`
	${InputField};
	height: 32px;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid ${colors.listsPage.inputBorderBottom};
	font-size: 1.25rem;
	font-weight: 700;
	outline: 0;
	padding-left: 0;

	&:hover:not(:disabled),
	&:focus:not(:disabled) {
		border: none;
		border-bottom: 1px solid ${colors.listsPage.inputBorderBottom};
		box-shadow: none;
	}
`;

export const InputContainer = styled.div<InputType>`
	${InputFieldContainer};
	margin-right: 16px;
	max-width: 700px;
	width: 100%;
`;

export const CancelButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
`;

export const ListsContainer = styled.div`
	display: flex;
	flex-shrink: 0;
`;

export const ListsLeft = styled.div`
	width: 100%;

	&.show-left {
		flex: 1;
		max-width: 400px;
		margin-right: 10px;
		transition: width 0.35s ease-in-out;

		& span {
			max-width: 192px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
`;

export const ListsRight = styled.div`
	flex: 1;
	display: none;

	&.visibility {
		display: block;
		transition: display 0.35s ease-in-out;
	}
`;

export const ItemActionContainer = styled.div`
	min-height: 26px;
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.xs};

	padding: 0 ${guttersWithRem.s};
`;

export const NoResultText = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const LinkButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.xs};
`;

export const LinkButton = styled.button`
	border: none;
	background-color: ${colors.transparent};
	cursor: pointer;

	${CTALink};
`;

const Styled = {
	Wrapper,
	Content,
	Container,
	FolderList,
	ButtonContainer,
	ActionContainer,
	BackLinkContainer,
	BackLink,
	ListTable,
	ListTableHead,
	ListTableTh,
	ListTableBody,
	Input,
	InputContainer,
	CancelButton,
	ListsContainer,
	ListsLeft,
	ListsRight,
	ItemActionContainer,
	NoResultText,
	LinkButtonsWrapper,
	LinkButton,
};

export default Styled;
