import styled, { css } from 'styled-components';

import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';

const CheckboxContainer = styled.div`
	display: inline-block;
`;

const Label = styled.label`
	display: block;
	position: relative;
	padding-left: 1.25rem;
	margin-bottom: 16px;
	cursor: pointer;
	user-select: none;

	&:has(input:disabled) {
		cursor: default;
	}
`;

const Checkmark = styled.span<{ indeterminate?: boolean }>`
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: ${(props) => (props.indeterminate ? colors.black : colors.white)};
	border: 1px solid ${colors.slate};
	border-radius: ${borderRadius.s};
	background-repeat: no-repeat;
	background-position: center;
	transition:
		background-image 0.2s ease-in-out,
		border-color 0.2s ease-in-out;
`;

const Input = styled.input<{ indeterminate?: boolean }>`
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;

	&:checked + ${Checkmark} {
		background-color: ${colors.black};
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTAuODE5MyAwLjI3MTcwNEMxMS4wODIyIDAuNjA1NDAyIDExLjA1NTEgMS4xMTU4OSAxMC43NTg3IDEuNDExOTFMNS4zNjczOSA2Ljc5NjUyQzUuMTA2OTUgNy4wNTY2NCA0LjcxODIzIDcuMDY4NjggNC40NDU0OCA2LjgyNTA4TDAuMjcxNTc4IDMuMDk3MjhDLTAuMDM4ODIwNyAyLjgyMDA1IC0wLjA5MDg0IDIuMzEyMDEgMC4xNTUzOSAxLjk2MjU0QzAuNDAxNjIgMS42MTMwNyAwLjg1Mjg1NyAxLjU1NDUgMS4xNjMyNiAxLjgzMTczTDQuODY1NTYgNS4xMzgzNEw5LjgwNjUzIDAuMjAzNDg3QzEwLjEwMjkgLTAuMDkyNTM1NCAxMC41NTYzIC0wLjA2MTk5MzMgMTAuODE5MyAwLjI3MTcwNFoiIGZpbGw9IiNGMkYyRjIiLz4KPC9zdmc+Cg==);
		border-color: transparent;
	}

	&:checked:hover:not(:disabled) + ${Checkmark} {
		background-color: ${colors.steel};
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTAuODE5MyAwLjI3MTcwNEMxMS4wODIyIDAuNjA1NDAyIDExLjA1NTEgMS4xMTU4OSAxMC43NTg3IDEuNDExOTFMNS4zNjczOSA2Ljc5NjUyQzUuMTA2OTUgNy4wNTY2NCA0LjcxODIzIDcuMDY4NjggNC40NDU0OCA2LjgyNTA4TDAuMjcxNTc4IDMuMDk3MjhDLTAuMDM4ODIwNyAyLjgyMDA1IC0wLjA5MDg0IDIuMzEyMDEgMC4xNTUzOSAxLjk2MjU0QzAuNDAxNjIgMS42MTMwNyAwLjg1Mjg1NyAxLjU1NDUgMS4xNjMyNiAxLjgzMTczTDQuODY1NTYgNS4xMzgzNEw5LjgwNjUzIDAuMjAzNDg3QzEwLjEwMjkgLTAuMDkyNTM1NCAxMC41NTYzIC0wLjA2MTk5MzMgMTAuODE5MyAwLjI3MTcwNFoiIGZpbGw9IiM0RDRCNEMiLz4KPC9zdmc+Cg==);
		border-color: ${colors.black};
	}

	${(props) =>
		props.indeterminate
			? css`
					& + ${Checkmark} {
						background-color: ${colors.black};
						background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDEyIDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFIMTEiIHN0cm9rZT0iI0ZBRkFGQSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==);
						border-color: transparent;
					}
				`
			: ''}

	&:disabled {
		cursor: default !important;
	}
`;

const Styled = {
	CheckboxContainer,
	Label,
	Checkmark,
	Input,
};

export default Styled;
