import styled, { css } from 'styled-components';

import Input from 'components/Forms/Input';
import newColors from 'styles/theme/colors';
import { scrollbarX } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';

type Styles = {
	width: string | number | undefined;
	minWidth: string | number | undefined;
	maxWidth: string | number | undefined;
};

const borderStyle = css`
	background-image: linear-gradient(to right, ${colors.TableV2.borderDotsColor} 13%, rgba(136, 136, 136, 0) 0%);
	background-position: bottom;
	background-size: 8px 1px;
	background-repeat: repeat-x;
`;

const StyledTable = styled.table`
	width: 100%;
	border-collapse: collapse;

	input {
		-webkit-appearance: auto;
	}
`;

const StyledThead = styled.thead`
	${borderStyle};
	position: sticky;
	top: 0;
	background-color: ${({ theme }) => theme.table.thHeadBackground};
	z-index: 2;
`;

const StyledTh = styled.th<{ styles: Styles }>`
	text-align: left;
	padding: 0.5rem 1rem;
	color: ${({ theme }) => theme.table.thHeadColor};
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	width: ${(props) => (props.styles.width ? `${props.styles.width}px` : undefined)};
	min-width: ${(props) => (props.styles.minWidth ? `${props.styles.minWidth}px` : undefined)};
	max-width: ${(props) => (props.styles.maxWidth ? `${props.styles.maxWidth}px` : undefined)};
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	&:first-child {
		padding-right: 0;
	}
	&:nth-child(2) {
		padding-left: 0;
	}
`;

const StyledTbody = styled.tbody``;

const StyledTr = styled.tr`
	transition: background-color 0.2s ease-in-out;
	background-color: ${({ theme }) => theme.table.backgroundOdd};

	${borderStyle};
	&:nth-child(even) {
		background-color: ${({ theme }) => theme.table.backgroundEven};
	}

	&:hover {
		background-color: ${({ theme }) => theme.table.hoverBackground};
	}
`;

const StyledTd = styled.td<{ styles: Styles }>`
	vertical-align: middle;
	padding: 1rem;
	width: ${(props) => (props.styles.width ? `${props.styles.width}px` : undefined)};
	min-width: ${(props) => (props.styles.minWidth ? `${props.styles.minWidth}px` : undefined)};
	max-width: ${(props) => (props.styles.maxWidth ? `${props.styles.maxWidth}px` : undefined)};
	text-align: ${(props) => (props.align === 'center' ? 'center' : props.align === 'right' ? 'right' : 'left')};
	&:nth-child(1) {
		padding-right: 0;
	}
	&:nth-child(2) {
		padding-left: 0;
	}
`;

const StyledInput = styled(Input)``;

const StyledInputContainer = styled.div`
	display: inline-block;
	position: relative;
	padding-left: 1.5rem;
	margin-right: 10px;
	cursor: pointer;

	&::before {
		content: '';
		display: inline-block;
		width: 16px;
		height: 16px;
		border: 2px solid #000;
		position: absolute;
		left: 0;
		top: 0;
	}

	${StyledInput}:checked + .checkbox::before {
		content: ' ';
		font-size: 16px;
		position: absolute;
		left: 0;
		top: 0;
	}
`;

const ArrowButton = styled.button`
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzY4MzRfMzgwNzkpIj4KPHBhdGggZD0iTTEwLjQzMiA2Ljg0TDcuOCA0LjJDNy43MzYgNC4xMzYgNy42NDggNC4wODggNy41NTIgNC4wNDhDNy40OCA0LjAxNiA3LjQwOCA0IDcuMzI4IDRINy4zMTJDNy4yMjQgNCA3LjE0NCA0LjAyNCA3LjA2NCA0LjA1NkM2Ljk2OCA0LjA5NiA2Ljg4IDQuMTQ0IDYuODI0IDQuMkw0LjE3NiA2Ljg0QzMuOTQ0IDcuMDcyIDMuOTQ0IDcuNDU2IDQuMTc2IDcuNjg4QzQuNDA4IDcuOTIgNC43OTIgNy45MiA1LjAyNCA3LjY4OEw2LjcwNCA2LjAwOFYxNS42MjRDNi43MDQgMTUuOTUyIDYuOTc2IDE2LjIyNCA3LjMwNCAxNi4yMjRDNy42MzIgMTYuMjI0IDcuOTA0IDE1Ljk1MiA3LjkwNCAxNS42MjRWNi4wMDhMOS41ODQgNy42ODhDOS43MDQgNy44MDggOS44NTYgNy44NjQgMTAuMDA4IDcuODY0QzEwLjE2IDcuODY0IDEwLjMxMiA3LjgwOCAxMC40MzIgNy42ODhDMTAuNjY0IDcuNDU2IDEwLjY2NCA3LjA3MiAxMC40MzIgNi44NFoiIGZpbGw9IiMzMzMzMzMiLz4KPHBhdGggZD0iTTEzLjU2OCAxNy4xNkwxNi4yMDggMTkuOEMxNi4yNzIgMTkuODY0IDE2LjM2IDE5LjkxMiAxNi40NTYgMTkuOTUyQzE2LjUyOCAxOS45ODQgMTYuNiAyMCAxNi42OCAyMEgxNi42OTZDMTYuNzg0IDIwIDE2Ljg2NCAxOS45NzYgMTYuOTQ0IDE5Ljk0NEMxNy4wNCAxOS45MDQgMTcuMTI4IDE5Ljg1NiAxNy4xODQgMTkuOEwxOS44MjQgMTcuMTZDMjAuMDU2IDE2LjkyOCAyMC4wNTYgMTYuNTQ0IDE5LjgyNCAxNi4zMTJDMTkuNTkyIDE2LjA4IDE5LjIwOCAxNi4wOCAxOC45NzYgMTYuMzEyTDE3LjI5NiAxNy45OTJWOC4zNzZDMTcuMjk2IDguMDQ4IDE3LjAyNCA3Ljc3NiAxNi42OTYgNy43NzZDMTYuMzY4IDcuNzc2IDE2LjA5NiA4LjA0OCAxNi4wOTYgOC4zNzZWMTcuOTkyTDE0LjQxNiAxNi4zMTJDMTQuMjk2IDE2LjE5MiAxNC4xNDQgMTYuMTM2IDEzLjk5MiAxNi4xMzZDMTMuODQgMTYuMTM2IDEzLjY4OCAxNi4xOTIgMTMuNTY4IDE2LjMxMkMxMy4zMzYgMTYuNTQ0IDEzLjMzNiAxNi45MjggMTMuNTY4IDE3LjE2WiIgZmlsbD0iIzMzMzMzMyIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzY4MzRfMzgwNzkiPgo8cmVjdCB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0IDQpIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-color: transparent;
	transition: background-color 0.2s ease-in-out;
	width: 24px;
	height: 24px;
	display: inline-block;
	border: none;
	margin-left: 8px;
	cursor: pointer;
	border-radius: 4px;

	&.sort-desc,
	&.sort-asc,
	&:hover {
		background-color: #c1c1c1;
	}

	&.sort-desc {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjAzMTQgOC41NzM4TDEyLjQ4MDcgNS44MDk0QzEyLjQxODcgNS43NDIzOCAxMi4zMzM0IDUuNjkyMTIgMTIuMjQwMyA1LjY1MDI0QzEyLjE3MDYgNS42MTY3MyAxMi4xMDA4IDUuNTk5OTggMTIuMDIzMyA1LjU5OTk4SDEyLjAwNzhDMTEuOTIyNSA1LjU5OTk4IDExLjg0NDkgNS42MjUxMSAxMS43Njc0IDUuNjU4NjFDMTEuNjc0NCA1LjcwMDUgMTEuNTg5MSA1Ljc1MDc2IDExLjUzNDggNS44MDk0TDguOTY4NjMgOC41NzM4QzguNzQzNzkgOC44MTY3MyA4Ljc0Mzc5IDkuMjE4ODIgOC45Njg2MyA5LjQ2MTc2QzkuMTkzNDYgOS43MDQ2OSA5LjU2NTYgOS43MDQ2OSA5Ljc5MDQzIDkuNDYxNzZMMTEuNDE4NSA3LjcwMjU5VjE3Ljc3MTdDMTEuNDE4NSAxOC4xMTUyIDExLjY4MjEgMTguNCAxMiAxOC40QzEyLjMxNzkgMTguNCAxMi41ODE1IDE4LjExNTIgMTIuNTgxNSAxNy43NzE3VjcuNzAyNTlMMTQuMjA5NiA5LjQ2MTc2QzE0LjMyNTkgOS41ODc0MSAxNC40NzMyIDkuNjQ2MDUgMTQuNjIwNSA5LjY0NjA1QzE0Ljc2NzggOS42NDYwNSAxNC45MTUxIDkuNTg3NDEgMTUuMDMxNCA5LjQ2MTc2QzE1LjI1NjIgOS4yMTg4MiAxNS4yNTYyIDguODE2NzMgMTUuMDMxNCA4LjU3MzhaIiBmaWxsPSIjMzMzMzMzIi8+Cjwvc3ZnPgo=);
	}
	&.sort-asc {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguOTY4NjIgMTUuNDI2MkwxMS41MTkzIDE4LjE5MDZDMTEuNTgxMyAxOC4yNTc2IDExLjY2NjYgMTguMzA3OSAxMS43NTk3IDE4LjM0OThDMTEuODI5NCAxOC4zODMzIDExLjg5OTIgMTguNCAxMS45NzY3IDE4LjRMMTEuOTkyMiAxOC40QzEyLjA3NzUgMTguNCAxMi4xNTUxIDE4LjM3NDkgMTIuMjMyNiAxOC4zNDE0QzEyLjMyNTYgMTguMjk5NSAxMi40MTA5IDE4LjI0OTIgMTIuNDY1MiAxOC4xOTA2TDE1LjAzMTQgMTUuNDI2MkMxNS4yNTYyIDE1LjE4MzMgMTUuMjU2MiAxNC43ODEyIDE1LjAzMTQgMTQuNTM4MkMxNC44MDY1IDE0LjI5NTMgMTQuNDM0NCAxNC4yOTUzIDE0LjIwOTYgMTQuNTM4MkwxMi41ODE1IDE2LjI5NzRMMTIuNTgxNSA2LjIyODNDMTIuNTgxNSA1Ljg4NDg0IDEyLjMxNzkgNS42MDAwMiAxMiA1LjYwMDAyQzExLjY4MjEgNS42MDAwMiAxMS40MTg1IDUuODg0ODQgMTEuNDE4NSA2LjIyODNMMTEuNDE4NSAxNi4yOTc0TDkuNzkwNDMgMTQuNTM4MkM5LjY3NDEzIDE0LjQxMjYgOS41MjY4MyAxNC4zNTQgOS4zNzk1MyAxNC4zNTRDOS4yMzIyMiAxNC4zNTQgOS4wODQ5MiAxNC40MTI2IDguOTY4NjIgMTQuNTM4MkM4Ljc0Mzc5IDE0Ljc4MTIgOC43NDM3OSAxNS4xODMzIDguOTY4NjIgMTUuNDI2MloiIGZpbGw9IiMzMzMzMzMiLz4KPC9zdmc+Cg==);
	}
`;

const ThInnerWraper = styled.div`
	display: flex;
	align-items: center;
`;

const TableContainer = styled.div`
	width: 100%;
	${scrollbarX};
	.table-search {
		width: 100%;
		max-width: 350px;
		float: right;
		margin-bottom: 24px;
	}

	&.fixed-header {
		padding-bottom: 6rem;
		${StyledTr} {
			&:hover {
				cursor: pointer;
			}
		}
	}
	&.on-start-page {
		height: 90vh;
		.input-container {
			@media only screen and (${devices.lg}) {
				width: 350px;
			}
		}
		${StyledTr} {
			&:hover {
				cursor: pointer;
			}
		}
	}
	&.cursor-pointer {
		${StyledTr} {
			&:hover {
				cursor: pointer;
			}
		}
	}
	&.influencer-management-table {
		padding-bottom: 6rem;
		${StyledTr} {
			&:hover {
				cursor: pointer;
			}
		}
	}
	&.admin-table {
		padding-bottom: 10rem;

		${StyledTr} {
			&:hover {
				cursor: pointer;
			}
		}
	}
	&.payments-table {
		height: 80vh;
		overflow: visible !important;
		.input-container {
			@media only screen and (${devices.lg}) {
				width: 350px;
			}
		}
		${StyledTr} {
			&:hover {
				cursor: pointer;
			}
		}
	}
`;

const EmptyMessageContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 1rem 2rem;
	height: 200px;
`;

const EmptyMessage = styled.span`
	font-size: 1.25rem;
	color: ${newColors.steel};
`;

const SelectContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const Styled = {
	StyledTable,
	StyledThead,
	StyledTh,
	StyledTbody,
	StyledTr,
	StyledTd,
	StyledInput,
	StyledInputContainer,
	ArrowButton,
	ThInnerWraper,
	TableContainer,
	EmptyMessageContainer,
	EmptyMessage,
	SelectContainer,
};

export default Styled;
