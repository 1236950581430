import styled from 'styled-components';

import Accordion from 'components/Accordion';
import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';

const Wrapper = styled(Accordion)`
	width: 100%;
	border-radius: ${borderRadius.m};
	background-color: ${colors.payment.errorBackground};
	color: ${colors.errorDarkRed};
	padding: 1.5rem;
	label {
		color: ${colors.errorDarkRed};
	}
	.accordion__title {
		padding-left: 0;
		font-family: ${typography.BaseFontFamiliy};
		font-weight: 600;
	}
	.accordion__header {
		margin-bottom: 0;
	}
`;

const AccordionTitle = styled.div`
	display: flex;
	color: ${colors.errorDarkRed};
`;

const Step = styled.div`
	display: flex;
	align-items: flex-start;
	width: 100%;
`;

const StepContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid ${colors.errorDarkRed};
	padding: 1rem 0;
	padding-top: 1.1rem;
	width: 100%;
	color: ${colors.errorDarkRed};
	&.clickable {
		cursor: pointer;
	}
	div {
		&.align-top {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			padding-right: 1rem;
			width: 100%;
			h5 {
				color: ${colors.errorDarkRed};
				font-size: 0.875rem;
				font-weight: 600;
				margin-bottom: 0;
			}
			p {
				color: ${colors.errorDarkRed};
				font-size: 0.875rem;
				font-weight: 400;
			}
		}
		&.align-center {
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-radius: ${borderRadius.s};
			width: 24px;
			height: 24px;
			background-color: ${colors.errorDarkRed};
			color: ${colors.mainWhite};
			transition: background-color 0.2s ease-in-out;
			.icon path {
				fill: ${colors.mainWhite};
			}
		}
	}
`;

const IconWrapper = styled.div`
	margin-top: 18px;
`;

const StepWrapper = styled.div`
	margin-top: 24px;
`;

const SubTitle = styled.p`
	margin-top: 8px;
	color: ${colors.errorDarkRed} !important;
`;

const CountryWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const SelectorWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-top: 24px;
`;

const ConfirmWrapper = styled.div`
	width: 110px;
	display: flex;
	justify-content: center;
`;

const ConfirmLink = styled.p`
	width: fit-content;
	margin-bottom: 24px !important;
	margin: 0;
	&:hover {
		font-weight: 600 !important;
		border-bottom: 1px solid ${newColors.smoke};
		cursor: pointer;
	}
`;

const SelectAction = styled.div`
	display: flex;
	justify-content: end;
`;

const Action = styled.p`
	margin: 0 8px;
	width: fit-content;
	&:hover {
		font-weight: 600 !important;
		border-bottom: 1px solid ${colors.errorDarkRed};
		cursor: pointer;
	}
`;

const Guess = styled.span`
	margin-left: 5px;
`;

export default {
	ConfirmWrapper,
	Wrapper,
	AccordionTitle,
	Step,
	StepContent,
	IconWrapper,
	StepWrapper,
	SubTitle,
	CountryWrapper,
	SelectorWrapper,
	ConfirmLink,
	SelectAction,
	Action,
	Guess,
};
