import styled from 'styled-components';

import Modal from 'components/Modals/Modal';
import colors from 'styles/theme/colors';

const CardContent = styled.div`
	padding: 0 0.5rem;
`;

const CardImage = styled.img`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	user-select: none;
	object-fit: cover;
	transition: transform 0.2s ease-in-out;
`;

const CardVideo = styled.div`
	height: 30px;
	iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		user-select: none;
		object-fit: cover;
		min-height: 100%;
		min-width: 100%;
	}
`;

const CardImageInner = styled.div`
	border-radius: 10px;
	overflow: hidden;
	padding-bottom: 100%;
	position: relative;
`;

const CardImageWrapper = styled.div`
	border-radius: 13px;
	overflow: hidden;
	position: relative;
	transition: border-color 0.2s ease-in-out;

	& + ${CardContent} {
		margin-top: 16px;
	}

	&:hover {
		${CardImage} {
			transform: scale(1.05);
		}
	}
`;

const Card = styled.div`
	position: relative;
	width: 200px;
	min-height: 342px;
	&.tall {
		${CardImageInner} {
			padding-top: 155.66%;
			padding-bottom: 0;
			.layer {
				display: none;
			}
		}

		${CardImage} {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}

	&.selected {
		${CardImageWrapper} {
			border-color: ${colors.black};
		}

		${CardImage} {
			transform: scale(1.05);
		}
		${CardImageInner} {
			.layer {
				display: flex;
				background-color: rgba(77, 75, 76, 0.652);
				border-radius: 10px;
				z-index: 2;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				min-height: 342px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				div {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
				.icon path {
					fill: ${colors.white};
				}
				span {
					color: ${colors.white};
					font-weight: 600;
					font-size: 1.063rem;
					margin-top: 8px;
				}
			}
		}
	}

	&.disabled {
		opacity: 0.3;
		cursor: default;
		&:not(.selected) {
			${CardImage} {
				transform: scale(1);
			}
		}

		button {
			margin-bottom: 20%;
			.icon svg > * {
				fill: none;
				stroke: ${colors.slate};
			}
		}
	}
`;

const VideoOverlay = styled.div`
	position: absolute;
	display: flex;
	align-items: end;
	justify-content: center;
	top: 4px;
	left: 4px;
	width: calc(100% - 8px);
	height: calc(100% - 8px);

	button {
		margin-bottom: 20%;
		.icon svg > * {
			transition: stroke 0.2s ease-in-out;
			fill: none;
		}
	}
`;

const PlayButton = styled.button`
	position: absolute;
	right: 0;
	left: 0;
	top: auto;
	bottom: 30%;
	height: 24px;
	max-width: 100px;
	margin: auto;
	text-align: left;
	background-color: ${colors.black};
	border-radius: 4px;
	color: ${colors.white};
	border: none;
`;

const Iframe = styled.iframe`
	border: none;
	width: 100%;
	height: 100%;
	margin-top: 12px;
	border-radius: 8px;
`;

const ModalInner = styled.div`
	padding-top: 1.25rem;
`;

const ModalHeader = styled.div`
	display: flex;

	button {
		background: none;
		border: none;
		padding: 0;
		margin-left: auto;
		cursor: pointer;
	}
`;

const CustomModal = styled(Modal)`
	button {
		background: transparent;
		margin-top: -35px;
		margin-right: -8px;
		display: flex;
		justify-content: flex-end;
		align-items: end;
		color: ${colors.white};
		font-size: 0.75rem;
		.icon {
			margin-left: 8px;
			path {
				fill: ${colors.white};
			}
		}
		span {
			margin-bottom: 2px;
		}
	}
	div {
		margin: auto;
		margin-top: 10vh;
		width: 300px;
		height: 540px;
		border-radius: 10px;
		border: none;
	}
`;

const ShowFullSizeButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	position: absolute;
	bottom: -27px;
	right: 10px;
	background-color: ${colors.black};
	border: transparent;
	cursor: pointer;
	border-radius: 4px;
	padding: 0.5rem;
	z-index: 10;
	.icon {
		line-height: 0;

		svg path {
			fill: ${colors.white};
		}
	}
`;

const Styled = {
	ShowFullSizeButton,
	Card,
	CustomModal,
	CardImageWrapper,
	CardImageInner,
	CardImage,
	CardVideo,
	VideoOverlay,
	PlayButton,
	Iframe,
	ModalInner,
	ModalHeader,
	CardContent,
};

export default Styled;
