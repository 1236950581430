import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { devices, mediaQueries } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const ChatBoxOuter = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Wrapper = styled.div`
	width: 80%;
	margin-bottom: ${guttersWithRem.xxl};
	display: flex;

	&.left {
		float: left;
	}

	&.right {
		float: right;
		flex-direction: row-reverse;

		${ChatBoxOuter} {
			align-items: end;
		}
	}
`;

const AvatarWrapper = styled.div`
	width: 24px;
	height: 24px;

	& > figure {
		margin: 0;
	}

	figcaption {
		margin-top: 0;
	}

	&.no-image {
		& > figure {
			background-color: ${colors.transparent};
			border: 2px solid ${colors.lightGray};
		}
	}
`;

const ChatBox = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	min-width: 56px;
	padding: 2rem;
	transition: background-color 200ms ease-in-out;
	margin-top: 8px;
	position: relative;
	white-space: pre-line;
	word-wrap: break-word;
	p {
		color: ${colors.integratedInbox.chatSection.bubble.text};
		margin-bottom: 0;
		margin-top: 0;
		width: 100%;
		max-width: 200px;
		@media screen and (${devices.lg}) {
			max-width: 400px;
		}

		p + p {
			margin-bottom: 0.25rem;
		}
	}
	&.left {
		margin-left: ${guttersWithRem.xs};
		background-color: ${newColors.mist};
		border-radius: 0px 10px 10px 10px;
	}

	&.right {
		margin-right: ${guttersWithRem.xs};
		background-color: ${colors.contentManagement.background};
		border-radius: 10px 0px 10px 10px;
	}
`;

const SendTime = styled.div`
	position: absolute;
	bottom: 4px;
	right: 8px;

	font-size: 0.6rem;
	font-family: ${typography.BaseFontFamiliy};
	color: ${colors.integratedInbox.chatSection.date.color};
`;

const ReceiverHasSeenMessage = styled.div`
	text-align: right;
	width: auto;
	align-self: flex-end;
	margin-left: auto;
	padding-right: 0.625rem;

	font-size: 0.75rem;
	color: ${colors.integratedInbox.chatSection.date.color};
`;

const MessageFrom = styled.div`
	position: absolute;
	bottom: -16px;
	left: 8px;
	min-width: 300px;

	font-size: 0.75rem;
	color: ${colors.integratedInbox.chatSection.date.color};
`;

const ChatBoxWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: fit-content;

	&.chat-box--attachment {
		cursor: pointer;
		margin-top: 16px;
		width: 360px;

		${mediaQueries.large`
				width: 360px;
		`};

		.cb-spinner {
			margin: 8px;
			width: 23px;
			height: 23px;
		}

		${ChatBox} {
			&.left:hover {
				background-color: ${colors.lightGray} !important;
			}

			&.right:hover {
				background-color: ${colors.lightGray} !important;
			}
		}

		&.chat-box--attachment ${ChatBox} {
			border: 1px solid ${colors.lightGray};
			background-color: ${colors.integratedInbox.chatSection.bubble.attachment.background} !important;
		}
	}

	.tooltip {
		display: flex;

		.show {
			left: auto;
			right: 0;
			margin-right: 0;
		}
	}
`;

const FileTypeLabel = styled.span`
	color: ${colors.integratedInbox.chatSection.bubble.fileTypeLabel};
`;

const FileName = styled.div`
	display: inline-flex;
	max-width: 322px;
	padding-right: 0.25rem;

	span {
		width: 166px;
		display: inline-block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const DownloadButton = styled.button`
	background: transparent;
	border: none;
	padding: 4px;
	width: 40px;
	height: 40px;
	border-radius: 50px;
	cursor: pointer;

	svg path {
		fill: #4d4b4c;
	}
`;

const DownloadTimestamp = styled.div`
	display: block;
	color: ${colors.integratedInbox.chatSection.bubble.DownloadTimestamp};
	font-size: 0.6rem;
	text-align: right;
`;

const DownloadContainer = styled.div`
	width: 40px;
	height: 50px;
`;

const Styled = {
	MessageFrom,
	ChatBoxWrapper,
	ChatBoxOuter,
	Wrapper,
	AvatarWrapper,
	ChatBox,
	SendTime,
	ReceiverHasSeenMessage,
	DownloadButton,
	FileTypeLabel,
	FileName,
	DownloadContainer,
	DownloadTimestamp,
};

export default Styled;
