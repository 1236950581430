import styled from 'styled-components';

import colors from 'styles/theme/colors';

const Wrapper = styled.div``;

const ModalTextWrapper = styled.div`
	margin-top: 10px;
	p {
		color: ${colors.steel};
	}
`;

const Styled = {
	Wrapper,
	ModalTextWrapper,
};

export default Styled;
