import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { devices, mediaQueries } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	width: 100%;
	position: relative;
	margin: 0 auto;
`;

const Content = styled.div`
	max-width: 1920px;
	padding: 16px;
	padding-bottom: 3rem;
	margin: 0 auto;
`;

const Widgets = styled.div`
	${mediaQueries.medium`
		padding: 0 2rem;
	`};
`;

const GridContainer = styled.div`
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(12, 1fr);
	align-items: start;

	> div {
		height: 100%;
	}
`;

const Header = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	@media only screen and (${devices.md}) {
		flex-direction: row;
		justify-content: space-between;
	}
`;

const Title = styled.h3`
	width: fit-content;
	white-space: nowrap;
	margin: 0;
`;

const Tab = styled.div`
	padding: 0.9rem 1.5rem;
	height: 44px;
	border-radius: ${borderRadius.m};
	border: 1px solid ${newColors.silver};
	cursor: pointer;
	span {
		color: ${newColors.dust};
	}
	&.selected {
		background-color: ${newColors.smoke};
		color: ${colors.mainWhite};
	}
`;

const TabContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	position: sticky;
	margin-bottom: 40px;
	scroll-margin-top: 100px; /* When we "scroll-to" this element, make sure the navbar is not blocking */
	@media only screen and (${devices.md}) {
		flex-direction: row;
	}
`;

const SearchSection = styled.div`
	margin-top: 40px;
	height: 80vh;
`;

const Status = styled.div`
	color: ${colors.black};
	&.active {
		color: ${colors.success};
	}
`;

const SpinnerContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const Styled = {
	Wrapper,
	Content,
	Widgets,
	GridContainer,
	Header,
	Title,
	Tab,
	TabContainer,
	SearchSection,
	Status,
	SpinnerContainer,
};

export default Styled;
