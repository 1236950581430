import styled from 'styled-components';

import colors from 'styles/theme/colors';

const Wrapper = styled.div`
	padding: 2.5rem 1rem;
	background-color: ${colors.mist};
	border-radius: ${({ theme }) => theme.radius.default};
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-align: center;
	width: 100%;

	h5 {
		margin-top: 24px;
		font-weight: ${({ theme }) => theme.fontWeights.bold};
		font-size: 1rem;
		margin: 24px;
	}

	p {
		font-size: 1rem;
		margin: 0;
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
