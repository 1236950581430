import styled from 'styled-components';

import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import { guttersWithRem } from 'styles/variables/gutter';

const ToggleIcon = styled.div`
	margin-left: auto;
`;

const IconWrapper = styled.div`
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	border-radius: ${borderRadius.s};
	transition: background-color 0.2s ease-in-out;
`;

const Header = styled.div<{ iconPosition: 'left' | 'right'; backgroundColor?: string; hoverIconColor?: string }>`
	display: flex;
	align-items: center;
	font-size: 1rem;
	margin-bottom: 0.688rem;
	cursor: pointer;

	span {
		padding-right: 4px;
	}

	div {
		margin-left: ${(p) => (p.iconPosition !== 'left' ? 'auto' : '0')};
	}

	.icon {
		display: flex;
		align-items: center;
		width: auto;
		height: auto;
		transition: all 0.2s ease;
		transform: rotateZ(180deg);
	}
	.accordion__title {
		width: ${(p) => (p.iconPosition !== 'left' ? '100%' : 'auto')};
	}

	&:hover {
		${IconWrapper} {
			background-color: ${(props) => props.backgroundColor ?? ''};
			.icon path {
				fill: ${(props) => props.hoverIconColor ?? ''} !important;
			}
		}
	}
`;

const Body = styled.div`
	position: relative;
	overflow: hidden;
	max-height: 3000px;
	transition: max-height 1s ease-in-out;
	p:last-child {
		margin-bottom: 0;
	}
`;

const ToggleCloseIcon = styled.div`
	margin-left: auto;
`;

const Wrapper = styled.div<{ border?: boolean }>`
	display: flex;
	flex-direction: column;
	width: 100%;
	border-bottom: ${(p) => p.border && '1px solid #e2e2e2'};
	margin-bottom: ${(p) => p.border && '1.5rem'};
	padding-bottom: ${(p) => p.border && '1.5rem'};

	.accordion__title {
		&.active {
			font-weight: 600;
		}
	}

	&[aria-expanded='false'] {
		${Body} {
			max-height: 0;
			transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
		}

		${Header} {
			.icon {
				transform: rotateZ(0deg);
			}
			${ToggleCloseIcon} {
				.icon {
					transform: rotateZ(45deg);
				}
			}
		}
	}
	&[aria-expanded='true'] {
		${Header} {
			${ToggleCloseIcon} {
				.icon {
					transform: rotateZ(90deg) !important;
				}
			}
		}
	}
	&.brand-affiliations {
		${IconWrapper} {
			background-color: ${colors.smoke} !important;
		}
		.icon path {
			fill: ${colors.mist};
		}
	}

	&.promo-codes {
		&[aria-expanded='false'] {
			${Header} {
				.icon {
					transform: rotateZ(-90deg);
				}
			}
		}
		&[aria-expanded='true'] {
			${Header} {
				.icon {
					transform: rotate(0deg);
				}
			}
		}
	}
	&.coupon-modal {
		&[aria-expanded='false'] {
			${Header} {
				.icon {
					transform: rotateZ(-90deg);
				}
			}
		}
		&[aria-expanded='true'] {
			.accordion__header {
				background-color: ${colors.ash};
			}
			${Header} {
				.icon {
					transform: rotate(0deg);
				}
			}
		}
	}
	&.arrow-right-and-down {
		.icon {
			transform: rotate(0deg);
		}
		&[aria-expanded='false'] {
			${Header} {
				.icon {
					transform: rotateZ(-90deg);
				}
			}
		}
	}
	&.integrated-inbox-left-menu {
		display: block;
		.icon {
			transform: rotate(0deg);
		}
		&[aria-expanded='false'] {
			${Header} {
				.icon {
					transform: rotateZ(-90deg);
				}
			}
		}
		div {
			margin-left: 0;
		}

		.accordion__title {
			width: auto;
		}

		${Header} {
			padding-left: ${guttersWithRem.xxl};
		}

		${Body} {
			max-height: unset;
		}

		&[aria-expanded='false'] {
			${Body} {
				max-height: 0;
				transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
			}
		}
	}
`;

const Styled = {
	Wrapper,
	Header,
	Body,
	ToggleIcon,
	IconWrapper,
	ToggleCloseIcon,
};

export default Styled;
