import styled from 'styled-components';

import colors from 'styles/theme/colors';
import { mediaQueries } from 'styles/variables/media-queries';

const UploadWrapper = styled.div`
	display: flex;
	background-color: ${colors.mist};
	padding: 0.5rem;
	min-height: 270px;
	flex-direction: column;
	border-radius: 10px;
`;

const DndArea = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 2px;
	height: 100%;
	flex: 1;
`;

const DndContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	gap: 16px;
	padding: 34px;

	img {
		margin: 0 auto;
		max-width: 248px;

		${mediaQueries.large`
				max-width: 347px;
		`};
	}

	.icon svg path {
		fill: ${colors.black};

		&.cross {
			stroke: ${colors.black};
		}
	}

	button {
		width: 100%;
		max-width: 142px;
		margin: 0 auto;
	}
`;

const DndText = styled.span`
	font-size: 0.938rem;
	color: ${colors.slate};
`;

const UploadInput = styled.input`
	display: none;
`;

const ContentList = styled.div``;

const ErrorMessagesList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
`;

const ErrorMessagesListItem = styled.li`
	color: ${colors.burgundy};
`;

const SupportedFormats = styled.span`
	color: ${colors.slate};
	line-height: 1.3;
	font-size: 0.75rem;
`;

const Styled = {
	UploadWrapper,
	DndArea,
	DndText,
	DndContent,
	UploadInput,
	ContentList,
	ErrorMessagesList,
	ErrorMessagesListItem,
	SupportedFormats,
};

export default Styled;
