import styled, { DefaultThemeV2 } from 'styled-components';

import Avatar from 'components/Avatar';
import newColors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';

const IconContainer = styled.div`
	position: relative;
	cursor: pointer;
	max-height: 32px;
	i > svg {
		margin-top: 5px;
	}
`;

const CustomInput = styled.textarea`
	background-color: ${colors.dropdown.menu.background};
	border: 1px solid ${colors.dropdown.menu.borderColor};
	border-radius: 10px;
	height: 100px;
	width: 100%;
	padding: 0.5rem;
	&::placeholder {
		padding: 0.5rem;
		color: ${newColors.steel};
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 12px;
	}
	&:focus {
		outline: none;
	}
`;

const Dropdown = styled.div`
	display: block;
	top: 5px;
	right: 44px;
	width: 259px;
	height: auto;
	max-height: 275px;
	background-color: ${colors.dropdown.menu.background};
	position: absolute;
	border: 1px solid ${colors.dropdown.menu.borderColor};
	border-radius: 2px;
`;

const CommentsIconWrapper = styled.div`
	min-width: 32px;
	position: relative;
	.icons {
		position: absolute;
		top: 0;
		right: -5px;
	}
`;

const DropdownClose = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	cursor: pointer;
`;

const CommentList = styled.ul`
	list-style: none;
	${scrollbarY};
	max-height: 500px;
	height: auto;
	margin-bottom: 0;
	li {
		padding: 0.188rem 0.375rem;
		&.editMode {
			background-color: ${colors.white};
		}
	}
`;

const CommentListItem = styled.li`
	display: flex;
	margin-bottom: 10px;
	justify-content: space-between;
	height: auto;
	min-height: 100px;
`;

const CommentListItemAvatar = styled(Avatar)`
	margin: 0;
	height: 24px;
	width: 24px;
	min-width: 24px;
	margin-top: 2px;
	figcaption {
		margin-top: 0;
		span {
			font-size: 0.7rem;
		}
	}
`;

const CommentListItemContent = styled.div`
	width: 90%;
	flex-direction: column;
	padding: 0 0.5rem;
	margin-top: 5px;
`;

const CommentListItemContentHeader = styled.div`
	display: flex;
	align-items: baseline;
	width: 100%;
	justify-content: space-between;
	h5 {
		font-size: 0.975rem;
		margin-bottom: 0;
		max-width: 60%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	p {
		color: ${newColors.slate};
		margin: 0;
	}
`;

const CommentListItemContentText = styled.p`
	font-size: 0.75rem;
`;

const CreateNewCommentWrapper = styled.div`
	display: flex;
	padding: 0.188rem 0.375rem;
`;

const ErrorWrapper = styled.span`
	color: ${colors.red};
	margin-right: 2px;
`;

const CommentInputWrapper = styled.div`
	margin-left: 5px;
	text-align: end;
	width: 100%;
	padding: 0 0.5rem;
	p {
		font-size: 0.75rem;
		color: ${newColors.slate};
	}
`;

const ReactionsWrapper = styled.div`
	display: flex;
	align-items: baseline;
`;

const ReactionsIconWrapper = styled.div`
	cursor: pointer;
	min-width: 40px;
	display: flex;
	max-height: 32px;
	align-items: center;
	position: relative;
	.icons {
		position: absolute;
		top: 0;
		right: 3px;
	}
`;

const ModalHeader = styled.div`
	display: flex;
	padding-top: 10px;
`;
const ModalHeaderTitle = styled.div`
	display: flex;
	align-items: center;
	h5 {
		margin-right: 5px;
	}
`;

const AvatarContainer = styled.div`
	background-color: ${newColors.mist};
	align-self: stretch;
	display: flex;
	align-items: end;
	padding-right: 0.5rem;
	padding-bottom: 0.25rem;
`;

const CommentAvatar = styled(Avatar)`
	border: none;
	margin: 0;
`;

const ThreadTool = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: end;
	max-height: 250px;
	border-radius: 4px;
`;

const MessageFieldContainer = styled.div`
	display: flex;
	position: relative;
	justify-content: flex-end;
	align-items: end;
	flex-grow: 1;
	background-color: ${newColors.snow};
	border-top-left-radius: ${({ theme }: { theme: DefaultThemeV2 }) => theme.radius.small};
	border-bottom-left-radius: ${({ theme }: { theme: DefaultThemeV2 }) => theme.radius.small};
	padding: 0 1rem;
	border-radius: 4px;
`;

const MessageField = styled.div`
	user-select: text;
	white-space: pre-wrap;
	word-break: break-word;
	overflow-y: auto;
	padding: 0.75rem 0;
	outline: none;
	appearance: none;
	width: 100%;
	max-width: 100%;
	min-height: 40px;
	max-height: 240px;
	overflow-y: auto;
	resize: none;
	line-height: 1.5;

	&::-webkit-scrollbar {
		display: none;
	}
`;

const SendMessage = styled.span`
	display: inline-flex;
	background-color: ${newColors.snow};
`;

const SendButton = styled.button`
	border: none;
	width: 32px;
	height: 32px;
	padding: 0.25rem;
	line-height: 0;
	border-radius: ${({ theme }: { theme: DefaultThemeV2 }) => theme.radius.small};
	margin-bottom: 7px;
	background-color: ${newColors.paleGreenTint};
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;

	.icon {
		line-height: 2.25;

		path {
			transition: fill 0.2s ease-in-out;
		}
	}

	&:disabled {
		background-color: transparent;
		cursor: default;

		.icon path {
			fill: ${newColors.slate};
		}
	}
`;
const CommentsContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${newColors.mist};
	padding: 1rem;
	max-height: 600px;
	overflow-y: auto;
	border-radius: ${({ theme }: { theme: DefaultThemeV2 }) => theme.radius.default};
`;

const CommentsList = styled.div`
	min-height: 100px;
	overflow-y: auto;
	margin-bottom: 16px;
	flex-grow: 1;
`;

const MessageRow = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: 100%;

	&:not(.latest) {
		margin-bottom: 32px;
	}
`;

const UserAvatar = styled.div`
	padding-right: 1rem;
`;

const UserMessage = styled.div`
	white-space: pre-line;
	word-wrap: break-word;
	line-height: 1.5;
	max-width: 680px;
`;

const MessageStatus = styled.div`
	font-size: ${({ theme }: { theme: DefaultThemeV2 }) => theme.fontSizes.small};
	color: ${newColors.slate};
`;

const ContentWrapper = styled.div`
	display: flex;
	gap: 4px;
`;

const AvatarWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 0.875rem;
`;

const Styled = {
	AvatarWrapper,
	ContentWrapper,
	MessageStatus,
	MessageRow,
	UserAvatar,
	UserMessage,
	CommentsContainer,
	CommentsList,
	SendButton,
	SendMessage,
	ThreadTool,
	AvatarContainer,
	CommentAvatar,
	CommentsIconWrapper,
	MessageFieldContainer,
	MessageField,
	IconContainer,
	Dropdown,
	DropdownClose,
	CommentList,
	CommentListItem,
	CommentListItemAvatar,
	CommentListItemContent,
	CommentListItemContentHeader,
	CommentListItemContentText,
	CreateNewCommentWrapper,
	CommentInputWrapper,
	CustomInput,
	ErrorWrapper,
	ReactionsWrapper,
	ReactionsIconWrapper,
	ModalHeader,
	ModalHeaderTitle,
};

export default Styled;
