import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { devices, mediaQueries } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	background-color: ${colors.mainWhite};
	position: relative;
	width: 100%;
`;

const EmptyStateWrapper = styled.div`
	background-color: ${colors.campaignInvite.emptyState.background};
	padding: 1rem 2rem;
	height: 100%;
	min-height: 100vh;
	display: flex;
	${scrollbarY};
`;

const LinkAlreadyUsedWrapper = styled.div`
	height: 100%;
	width: 100%;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	${mediaQueries.medium`
		width: 60%;
	`};
	${mediaQueries.xl`
		width: 60%;
	`};
`;

const AlignCenter = styled.div`
	display: flex;
	flex-direction: column-reverse;
	${mediaQueries.xl`
		align-items: center;
 		flex-direction: row;
	`};
`;

const EmptyStateImage = styled.img`
	display: block;
	height: 80px;
	width: auto;
	margin: auto;
	margin-bottom: 32px;
	${mediaQueries.medium`
		margin-bottom: 40px;
	`};
	${mediaQueries.xl`
		margin-left: 64px;
		margin-bottom: 200px;
		height: 160px;
	`};
`;

const Title = styled.h2`
	font-size: 1.75rem;
	font-family: ${typography.BaseFontFamiliy};
	font-weight: 500;
	${mediaQueries.medium`
		font-size: 2.5rem;
	`};
	${mediaQueries.xl`
		font-size: 3rem;
	`};
`;

const Text = styled.p`
	font-size: 0.938;
	margin: 32px 0;
	${mediaQueries.xl`
		font-size: 1.125rem;
	`};
`;

const TextContainer = styled.div`
	width: 100%;
	${mediaQueries.xl`
		width: 70%
	`};
	button {
		margin: auto;
		margin-bottom: 64px;
		width: 280px;
	}
	${mediaQueries.xl`
		button {
			margin: unset;
			margin-bottom: 0;
			width: auto;
		}
	`};
`;

const ImgContainer = styled.div`
	width: 100%;
	${mediaQueries.xl`
		width: 30%
	`};
`;

const Container = styled.div`
	width: 100%;
	padding: 1rem;
	@media screen and (${devices.lg}) {
		width: 800px;
		margin: auto;
	}
`;

const CampaignName = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 19.2px;
	font-weight: 700;
	line-height: 150%;
`;

const CoverImage = styled.div<{ backgroundUrl: string }>`
	background: url(${(props) => props.backgroundUrl});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	margin-top: 40px;
	border-radius: ${borderRadius.m};
	box-shadow: 0px 10px 20px 0px rgba(92, 104, 128, 0.16);
`;
const ImageWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	&.isInfluencer {
		margin-top: 80px;
	}
	&.isPublisher {
		margin-top: 250px;
	}
	@media screen and (${devices.lg}) {
		width: 80%;
		&.isPublisher {
			margin-top: 300px;
		}
		&.isInfluencer {
			margin-top: 200px;
		}
	}
`;

const CampaignInfoBriefImageWrapper = styled.div`
	margin: 0 auto;
	width: 100%;
`;

const BrandLogo = styled.img`
	height: 32px;
	width: auto;
	border-right: 1px solid ${newColors.silver};
	padding-right: 1rem;
	margin-right: 16px;
`;

const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 16px;
`;

const PublisherLogotype = styled.div`
	img {
		height: 32px;
		width: auto;
	}
`;

const OfficialPartner = styled.p`
	font-size: 0.625rem;
	margin-left: 8px;
`;

const PublisherWrapper = styled.div`
	display: flex;
`;

const ToggleCoverImageButtonContainer = styled.div`
	display: flex;
`;

const ShowCoverImage = styled.button`
	display: flex;
	align-items: center;
	gap: 8px;
	background-color: transparent;
	border: none;
	margin-left: auto;
	cursor: pointer;
`;

const Styled = {
	PublisherWrapper,
	BrandLogo,
	LogoWrapper,
	CampaignName,
	TextContainer,
	ImgContainer,
	EmptyStateWrapper,
	Wrapper,
	LinkAlreadyUsedWrapper,
	EmptyStateImage,
	Title,
	AlignCenter,
	Text,
	Container,
	CoverImage,
	ImageWrapper,
	PublisherLogotype,
	OfficialPartner,
	CampaignInfoBriefImageWrapper,
	ToggleCoverImageButtonContainer,
	ShowCoverImage,
};

export default Styled;
