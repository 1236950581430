import styled, { DefaultThemeV2 } from 'styled-components';

import colors from 'styles/theme/colors';
import { fontWeights } from 'styles/variables/font-weights';
import { devices } from 'styles/variables/media-queries';

type T = { theme: DefaultThemeV2 };

const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	align-items: center;
	margin-bottom: 32px;
	button {
		white-space: nowrap;
	}
	@media screen and (${devices.lg}) {
		flex-direction: row;
		justify-content: space-between;
	}
`;

export const StyledCard = styled.div`
	display: flex;
	width: 100%;
	background-color: ${colors.mist};
	border-radius: ${(props: T) => props.theme.radius.default};
	padding: 1.5rem;
	align-items: flex-start;
	flex-direction: column;
	gap: 24px;
	@media screen and (${devices.lg}) {
		justify-content: space-between;
	}
`;

const BrandLogosWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	gap: 8px;
	padding-bottom: 5rem;
	margin-top: 16px;
	&.searching {
		height: 500px;
		overflow-y: auto;
	}
`;

const BrandItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${colors.powderVioletDark};
	width: 120px;
	height: 120px;
	border-radius: ${(props: T) => props.theme.radius.default};
	cursor: pointer;
	img {
		display: block;
		margin: auto;
	}
	h5 {
		margin: auto;
	}
	&.hide {
		display: none;
		opacity: 0;
	}
`;

const SearchInputWrapper = styled.div`
	float: right;
	width: 350px;
	margin-bottom: 32px;
`;

const AddIcon = styled.div`
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${colors.ash};
	border-radius: 4px;
`;

const AddSection = styled.div`
	cursor: pointer;
	display: flex;
	gap: 8px;
	align-items: center;
	width: fit-content;
	p {
		margin-bottom: 0;
		color: ${colors.smoke};
		font-weight: ${fontWeights.bold};
	}
`;

const ListWrapper = styled.div`
	margin-top: 24px;
	max-height: 600px;
	overflow-y: auto;
	width: 100%;
`;

const EmptyResultImgWrapper = styled.div`
	max-width: 500px;
	margin: 0 auto;

	p strong {
		font-weight: ${({ theme }) => theme.fontWeights.bold};
	}
`;

export default {
	ListWrapper,
	AddSection,
	AddIcon,
	SearchInputWrapper,
	BrandItem,
	BrandLogosWrapper,
	ButtonsWrapper,
	StyledCard,
	EmptyResultImgWrapper,
};
