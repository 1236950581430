import styled from 'styled-components';

import colors from 'styles/theme/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const Wrapper = styled.div`
	display: none;
	position: absolute;

	padding: 0 ${guttersWithRem.xs};
	border-radius: 4px;
	font-size: 0.875rem;

	top: -10px;
	right: 15px;
	z-index: 1;
	padding: 0.3rem 0.5rem 0.2rem 0.5rem;

	&.Full,
	&.New {
		display: block;
	}

	&.New {
		background-color: ${colors.paleGreenTint};
		color: ${colors.forestGreen};
	}

	&.Full {
		background-color: ${colors.rose};
		color: ${colors.burgundy};
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
