import styled, { DefaultThemeV2 } from 'styled-components';

import newColors from 'styles/theme/colors';
import { gutters, guttersWithRem } from 'styles/variables/gutter';

type P = { theme: DefaultThemeV2 };

const Wrapper = styled.div``;

const HashtagsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: 8px;
	column-gap: ${gutters.xxs};
	row-gap: ${gutters.xxs};
`;

const hashtagItemPadding = `${guttersWithRem.xs} ${guttersWithRem.xs}`;
const HashtagItem = styled.div`
	display: flex;
	align-items: center;
	column-gap: 8px;
	padding: ${hashtagItemPadding};
	border: 1px solid ${newColors.ash};
	border-radius: ${({ theme }: P) => theme.radius.default};
	background-color: ${newColors.ash};
	color: ${newColors.black};
	cursor: pointer;

	& path {
		fill: ${newColors.black};
	}
`;

const EnterLabel = styled.div`
	color: ${newColors.slate};
`;

const IconContainer = styled.div`
	display: flex;
	cursor: pointer;
`;

const ContentAfterContainter = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;

	.icon {
		line-height: 0;
	}
`;

const Styled = {
	Wrapper,
	HashtagsWrapper,
	HashtagItem,
	EnterLabel,
	IconContainer,
	ContentAfterContainter,
};

export default Styled;
