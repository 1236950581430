import styled from 'styled-components';

import Accordion from 'components/Accordion';
import { detailItem, detailText, detailTitle } from 'components/NewBriefPage/NewBreifPage.style';
import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';

const Wrapper = styled.div`
	margin-bottom: -50px;
	height: auto;
`;

const Container = styled.div`
	line-height: 1.5;
	button {
		display: block;
	}
`;

const Heading = styled.h3`
	font-size: 1rem;
	color: ${newColors.steel};
	font-weight: 500;
`;

const TagsContainer = styled.div`
	margin-bottom: 12px;

	&:last-child {
		margin-bottom: 0;
	}
`;

const Tags = styled.div`
	display: flex;
	flex-flow: wrap;
`;

const Tag = styled.div`
	padding: 0.5rem;
	border: 1px solid ${newColors.spice};
	border-radius: 2px;
	background-color: ${newColors.butter};
	max-height: 28px;
	line-height: 0.6;
	margin-bottom: 8px;

	&:not(:last-child) {
		margin-right: 8px;
	}

	span {
		font-size: 0.875rem;
		color: ${newColors.spice};
	}
`;

const CustomAccordion = styled(Accordion)`
	cursor: pointer;
	p {
		color: ${newColors.slate};
	}

	.accordion__header {
		width: fit-content !important;
		color: ${newColors.black};
		line-height: 1.5;
		font-weight: 500;
		padding: 0.5rem;
		border-radius: ${borderRadius.m};
		&:hover {
			background-color: ${newColors.mist};
		}
	}
	${Heading} {
		cursor: pointer;
		color: ${newColors.slate};
		line-height: 1;
		margin-bottom: 0;
		transition: color 200ms ease-in-out;
	}

	&.selected,
	&:hover {
		${Heading} {
			color: ${newColors.steel};
		}
	}
	&.selected {
		.accordion__body {
			padding-bottom: 4rem;
			padding: 1rem;
			border-radius: 10px;
			background-color: #f2f2f2;
		}
	}
`;

const DescriptionList = styled.div`
	padding: 0;

	ul {
		margin: 0;
		padding-left: 1rem;

		li {
			white-space: pre-line;
			line-height: 1.5;
			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}
	}
`;

const TagsWrapper = styled.div`
	padding: 0 1.5rem;
`;

const CTAContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0.5rem 0 0.8125rem 0;
	margin-bottom: 20px;
	gap: 16px;
`;

const DetailItem = styled(detailItem)`
	hr {
		border-top: 1px solid ${newColors.ash};
	}
	p {
		color: ${newColors.black};
	}
`;
const DetailTitle = styled(detailTitle)`
	font-size: 0.875rem;
	color: ${({ theme }) => theme.colors.placeholder};
`;
const DetailText = styled(detailText)``;

export const Styled = {
	Wrapper,
	Container,
	Heading,
	TagsContainer,
	Tags,
	Tag,
	CustomAccordion,
	DescriptionList,
	TagsWrapper,
	CTAContainer,
	DetailItem,
	DetailTitle,
	DetailText,
};
