import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PublisherModel } from 'api-models';
import { ListPublishersQuery } from 'api-queries';
import HeaderDropdown from 'components/Header/Components/HeaderDropdown';
import Icon from 'components/Icon';
import UserAvatar from 'components/Settings/MyAccount/UserAvatar';
import { OrganizationHierarchyRole } from 'components/Settings/types';
import useInjection from 'hooks/useInjection';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import LogoutService from 'services/Authentication/Collabs-api/LogoutService';
import { getInitials } from 'shared/User/User.helpers';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import Styled from './UserMenu.style';
/**
 * User Menu
 * @returns {JSX.Element}
 */
const UserMenu = (): JSX.Element => {
	const userDispatch = useAppDispatch();
	const [userDropdownOpen, setUserDropdownOpen] = useState<boolean>(false);

	const user = useAppSelector((state) => state.user);
	const isInfluencer = user.permissions.isInfluencer;

	const manager = useInjection<PublisherManager>(PublisherManager);

	const { repository } = manager.listPublishers(
		RequestQueryBuilder.create<ListPublishersQuery>(['logo', 'edit', 'createInvitation', 'createClient', 'deleteLogo']),
	);
	const publishers = repository.findAll('publisher') as PublisherModel[];
	const activePublishers = publishers?.filter((publisher) => !publisher.attributes?.disabledAt);
	const isAdminOnPublisher = activePublishers?.some((publisher) => user.permissions.entities?.[publisher.id]?.role === OrganizationHierarchyRole.ADMINISTRATOR);

	const navigate = useNavigate();
	const logoutService = useInjection<LogoutService>(LogoutService);

	const logOut = async () => {
		await logoutService.logout(userDispatch);
		navigate('/login');
	};

	return (
		<>
			<HeaderDropdown
				isUserMenu
				actionButtonIcon={<span data-testid='initials'>{getInitials(user.name || '')}</span>}
				userDropdownOpen={userDropdownOpen}
				setUserDropdownOpen={setUserDropdownOpen}
			>
				<>
					<Styled.AccountMenu>
						<div data-testid='profiles-menu'>
							<Styled.UserAvatarWrapper>
								<UserAvatar fullName={user.name} />
							</Styled.UserAvatarWrapper>
						</div>
					</Styled.AccountMenu>

					<Styled.ActionDropDownLinks>
						{isInfluencer && (
							<Styled.ActionDropdownMenuItem id='add-profile'>
								<Styled.MenuLink data-testid='add-social-account' to='/influencer/settings/instagram' onClick={() => setUserDropdownOpen(false)}>
									<Icon name='add-account' size='20' /> <span>Add social account</span>
								</Styled.MenuLink>
							</Styled.ActionDropdownMenuItem>
						)}
						<Styled.ActionDropdownMenuItem>
							<Styled.MenuLink
								data-testid='account-settings-link'
								to={isInfluencer ? '/influencer/settings/account' : '/settings/account'}
								onClick={() => setUserDropdownOpen(false)}
							>
								<Icon name='settings' size='20' /> <span>My account</span>
							</Styled.MenuLink>
							{isAdminOnPublisher && (
								<Styled.MenuLink data-testid='account-settings-link-clients' to='/settings/company' onClick={() => setUserDropdownOpen(false)}>
									<Icon name='influencer' size='20' /> <span>Clients</span>
								</Styled.MenuLink>
							)}
						</Styled.ActionDropdownMenuItem>

						<Styled.ActionDropdownMenuItem noBorder>
							<Styled.MenuLink data-testid='sign-out-link' to='' onClick={() => logOut()}>
								<span>Sign out</span>
							</Styled.MenuLink>
						</Styled.ActionDropdownMenuItem>
					</Styled.ActionDropDownLinks>
				</>
			</HeaderDropdown>
		</>
	);
};

export default UserMenu;
