import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgYoutube(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<rect width='20' height='20' rx='10' fill='#F61C0D' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.31406 12.7978L13.783 10.1894C13.9475 10.111 13.9504 9.87779 13.7878 9.7954L8.31878 7.024C8.17275 6.94999 8 7.0561 8 7.21983V12.5996C8 12.761 8.16833 12.8673 8.31406 12.7978Z'
				fill='white'
			/>
		</svg>
	);
}
