import classNames from 'classnames';
import { FormikErrors } from 'formik';
import { checkVAT, countries } from 'jsvat';
import { useEffect, useState } from 'react';

import { SecondaryButton } from 'components/Button';
import { InputText } from 'components/Form/FormikElements';
import Icon from 'components/Icon';
import InfoBox from 'components/InfoBox/InfoBox';
import { isSweden } from 'components/Payment/Components/PaymentSettings/helpers';
import { CountryCodesEU } from 'components/Payment/types';
import Styled from 'components/Settings/Settings.style';

type BillingFormValues = {
	invoiceEmail: string | null;
	legalName: string | null;
	invoiceAddress: string | null;
	invoicePostalCode: string | null;
	invoiceCity: string | null;
	invoiceCountryCode: string | null;
	vatNumber: string | null;
};

type VatValidationProps = {
	values: BillingFormValues;
	setFieldValue: (
		field: string,
		value: string,
		shouldValidate?: boolean | undefined,
	) => Promise<void | FormikErrors<{
		invoiceEmail: string | null;
		legalName: string | null;
		invoiceAddress: string | null;
		invoicePostalCode: string | null;
		invoiceCity: string | null;
		invoiceCountryCode: string | null;
		vatNumber: string | null;
	}>>;
};

const VatValidation = ({ values, setFieldValue }: VatValidationProps) => {
	const [needToConfirmVat, setNeedToConfirmVat] = useState(false);
	const [vatIsValid, setVatIsValid] = useState(false);
	const [displayInfo, setDisplayInfo] = useState(false);
	const [displayVatValidation, setDisplayVatValidation] = useState(false);

	const VatChecked = (): JSX.Element => {
		return values.vatNumber && !vatIsValid ? (
			<Styled.ErrorIcon
				onClick={() => {
					setFieldValue('vatNumber', ''), setFieldValue('orgNumber', '');
				}}
			>
				<Icon name='circle-cross' size='16' />
			</Styled.ErrorIcon>
		) : (
			<></>
		);
	};

	useEffect(() => {
		const validateVat = (vatNumber: string, country: string) => {
			const validation = checkVAT(vatNumber, countries);
			const validCountry = validation.country;
			if (country === 'NOR') {
				setDisplayVatValidation(false);
				setVatIsValid(true);
				return;
			}
			if (vatNumber) {
				if (validCountry) {
					if (validCountry.isoCode.long === country && validation.isValidFormat) {
						setVatIsValid(true);
					} else {
						setVatIsValid(false);
					}
				}
			}
		};

		if (values.vatNumber && values.vatNumber.length > 5 && values.invoiceCountryCode) {
			validateVat(values.vatNumber, values.invoiceCountryCode);
		}
	}, [values.vatNumber, values.invoiceCountryCode]);

	const renderVatField = (country: string): JSX.Element => {
		if (isSweden(country)) {
			return (
				<div data-testid='section-sweden'>
					<InputText
						type='string'
						maxLength='10'
						name='orgNumber'
						label='Organization number'
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							const value = e.target.value.replace(/[^\d]/g, '').slice(0, 10);
							if (value !== e.target.value) {
								e.target.value = value;
							}
							setFieldValue('vatNumber', `SE${value}01`);
							setFieldValue('orgNumber', value);
						}}
						onPaste={async (e: React.ClipboardEvent<HTMLInputElement>) => {
							e.preventDefault();
							try {
								const value = await navigator.clipboard.readText();
								const cleanValue = value.replace(/[^\d]/g, '').slice(0, 10);
								setFieldValue('vatNumber', `SE${cleanValue}01`);
								setFieldValue('orgNumber', cleanValue);
							} catch (e) {
								console.error('Unable to access clipboard: %O', e);
							}
						}}
						onBlur={() => {
							setDisplayVatValidation(true);
						}}
						onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
							// Allow keyboard shortcuts (Ctrl+V, Ctrl+C, etc.)
							if (e.ctrlKey || e.metaKey) return;

							// Allow only digits and navigation keys
							if (!/^\d$/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)) {
								e.preventDefault();
							}
						}}
					/>
					{!vatIsValid && displayVatValidation && values.vatNumber && values.vatNumber.length > 0 && (
						<Styled.ErrorMessage data-testid='SE-error'>
							<Icon name='alert' size='14' /> <span>Wrong format, it should be 10 digits.</span>
						</Styled.ErrorMessage>
					)}
					<Styled.VatInput data-testid='section-VAT'>
						<label>VAT number</label>
						<Styled.Value>
							<span>{values.vatNumber}</span>
							<Styled.IconSection>
								{displayVatValidation && VatChecked()}
								<Styled.InfoBoxWrapper>
									<InfoBox
										displayInfo={displayInfo}
										setDisplayInfo={setDisplayInfo}
										text={'Entering your organization number automatically fills the VAT number'}
										arrowPosition={'21px'}
									/>
								</Styled.InfoBoxWrapper>
							</Styled.IconSection>
						</Styled.Value>
					</Styled.VatInput>
				</div>
			);
		} else if (CountryCodesEU.includes(country)) {
			return (
				<div data-testid='section-EU'>
					<InputText
						name='vatNumber'
						label='VAT number'
						value={values.vatNumber}
						required
						onBlur={() => {
							setNeedToConfirmVat(true), setDisplayVatValidation(true);
						}}
						action={<div>{displayVatValidation && VatChecked()}</div>}
					/>
					{!vatIsValid && displayVatValidation && values.vatNumber && values.vatNumber.length > 0 && (
						<Styled.ErrorMessage>
							<Icon name='alert' size='14' /> <span>Wrong format.</span>
						</Styled.ErrorMessage>
					)}
					<Styled.NeedToConfirmVat className={classNames({ needToConfirmVat: needToConfirmVat })}>
						<h4>Confirm VAT number</h4>
						<p>Please confirm the VAT number you have entered.</p>
						<SecondaryButton
							data-testid='confirm-vat'
							onClick={(e) => {
								setNeedToConfirmVat(false), e.preventDefault();
							}}
						>
							Yes, confirm
						</SecondaryButton>
					</Styled.NeedToConfirmVat>
				</div>
			);
		} else {
			return <div />;
		}
	};
	return values.invoiceCountryCode ? renderVatField(values.invoiceCountryCode) : <div />;
};

export default VatValidation;
