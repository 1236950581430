import { svgIconComponents } from 'components/Icon/types';

import { SvgAddAccount } from './svg-add-account';
import { SvgAddCircle } from './svg-add-circle';
import { SvgAddDocument } from './svg-add-document';
import { SvgAddToFolder } from './svg-add-to-folder';
import { SvgAMCampaign } from './svg-affiliate-campaign';
import { SvgAi } from './svg-ai';
import { SvgAlert } from './svg-alert';
import { SvgAll } from './svg-all';
import { SvgArchive } from './svg-archive';
import { SvgArrowDown } from './svg-arrow-down';
import { SvgArrowLargeLeft } from './svg-arrow-large-left';
import { SvgArrowLargeRight } from './svg-arrow-large-right';
import { SvgArrowLeft } from './svg-arrow-left';
import { SvgArrowRight } from './svg-arrow-right';
import { SvgArrowUp } from './svg-arrow-up';
import { SvgAssignmentPost } from './svg-assignment-post';
import { SvgAssignmentReel } from './svg-assignment-reel';
import { SvgAssignmentStory } from './svg-assignment-story';
import { SvgAudience } from './svg-audience';
import { SvgAutomaticPayments } from './svg-automatic-payments';
import { SvgBankId } from './svg-bank-id';
import { SvgBlast } from './svg-blast';
import { SvgBrand } from './svg-brand';
import { SvgCalendar } from './svg-calendar';
import { SvgCampaign } from './svg-campaign';
import { SvgCampaignBrief } from './svg-campaign-brief';
import { SvgCancelCircle } from './svg-cancel-circle';
import { SvgCelebrate } from './svg-celebrate';
import { SvgChangeSegment } from './svg-change-segment';
import { SvgChanges } from './svg-changes';
import { SvgCheckCircle } from './svg-check-circle';
import { SvgCheckCircleFilled } from './svg-check-circle-filled';
import { SvgCheckmark } from './svg-checkmark';
import { SvgChevronDown } from './svg-chevron-down';
import { SvgChevronLeft } from './svg-chevron-left';
import { SvgChevronLeftDouble } from './svg-chevron-left-double';
import { SvgChevronRight } from './svg-chevron-right';
import { SvgChevronRightDouble } from './svg-chevron-right-double';
import { SvgChevronUp } from './svg-chevron-up';
import { SvgCircle } from './svg-circle';
import { SvgCircleArrowLeft } from './svg-circle-arrow-left';
import { SvgCircleArrowRight } from './svg-circle-arrow-right';
import { SvgCircleArrowUp } from './svg-circle-arrow-up';
import { SvgCircleCross } from './svg-circle-cross';
import { SvgClick } from './svg-click';
import { SvgCollapse } from './svg-collapse';
import { SvgCollapseFilled } from './svg-collapse-filled';
import { SvgComment } from './svg-comment';
import { SvgCommentFilled } from './svg-comment-filled';
import { SvgCopy } from './svg-copy';
import { SvgCreditCard } from './svg-credit-card';
import { SvgCrop } from './svg-crop';
import { SvgCross } from './svg-cross';
import { SvgDirectMessage } from './svg-direct-message';
import { SvgDLActivations } from './svg-dl-activations';
import { SvgDLContent } from './svg-dl-content';
import { SvgDLConversions } from './svg-dl-conversions';
import { SvgDLCpe } from './svg-dl-cpe';
import { SvgDLCpr } from './svg-dl-cpr';
import { SvgDlFileInvoice } from './svg-dl-file-invoice';
import { SvgDLInfluencers } from './svg-dl-influencers';
import { SvgDLReachRate } from './svg-dl-reach-rate';
import { SvgDLSummaryContent } from './svg-dl-summary-content';
import { SvgDLVtr } from './svg-dl-vtr';
import { SvgDownload } from './svg-download';
import { SvgDrag } from './svg-drag';
import { SvgEmailMissing } from './svg-email-missing';
import { SvgEngagement } from './svg-engagement';
import { SvgExpand } from './svg-expand';
import { SvgExternalLink } from './svg-external-link';
import { SvgFile } from './svg-file';
import { SvgFilters } from './svg-filters';
import { SvgFlipHorizontal } from './svg-flip-horizontal';
import { SvgFlipVertical } from './svg-flip-vertical';
import { SvgFolder } from './svg-folder';
import { SvgFolderShared } from './svg-folder-shared';
import { SvgFolderSharedWithMe } from './svg-folder-shared-with-me';
import { SvgGrossReach } from './svg-gross-reach';
import { SvgHeart } from './svg-heart';
import { SvgHeartLiked } from './svg-heart-liked';
import { SvgHelp } from './svg-help';
import { SvgHideEye } from './svg-hide-eye';
import { SvgHome } from './svg-home';
import { SvghourGlass } from './svg-hour-glass';
import { SvgImage } from './svg-image';
import { SvgImpressions } from './svg-impressions';
import { SvgInfluencer } from './svg-influencer';
import { SvgInfoCircle } from './svg-info-circle';
import { SvgInstagram } from './svg-instagram';
import { SvgInstagramCircleColor } from './svg-instagram-circle-color';
import { SvgInstagramColor } from './svg-instagram-color';
import { SvgItemShared } from './svg-item-shared';
import { SvgItemSharedWithMe } from './svg-item-shared-with-me';
import { SvgJoinProfile } from './svg-join-profile';
import { SvgList } from './svg-list';
import { SvgListAdd } from './svg-list-add';
import { SvgListInfluencers } from './svg-list-influencers';
import { SvgListShared } from './svg-list-shared';
import { SvgListSharedWithMe } from './svg-list-shared-with-me';
import { SvgLock } from './svg-lock';
import { SvgLockCheckmark } from './svg-lock-checkmark';
import { SvgLookalike } from './svg-lookalike';
import { SvgMail } from './svg-mail';
import { SvgManualPayments } from './svg-manual-payments';
import { SvgMessage } from './svg-message';
import { SvgMessageEmpty } from './svg-message-empty';
import { SvgMessageFilled } from './svg-message-filled';
import { SvgMinus } from './svg-minus';
import { SvgMobileCheck } from './svg-mobile-check';
import { SvgMoney } from './svg-money';
import { SvgMoveCheck } from './svg-move';
import { SvgNewMessage } from './svg-new-message';
import { SvgNote } from './svg-note';
import { SvgNotification } from './svg-notification';
import { SvgOptions } from './svg-options';
import { SvgOtherFile } from './svg-other-file';
import { SvgPdfFile } from './svg-pdf-file';
import { SvgPen } from './svg-pen';
import { SvgPending } from './svg-pending';
import { SvgPlatformToggle } from './svg-platform-toggle';
import { SvgPlay } from './svg-play';
import { SvgPlus } from './svg-plus';
import { SvgProduct } from './svg-product';
import { SvgCoupon } from './svg-promo-code';
import { SvgReach } from './svg-reach';
import { SvgRefresh } from './svg-refresh';
import { SvgRotateLeft } from './svg-rotate-left';
import { SvgRotateRight } from './svg-rotate-right';
import { SvgSalesBudget } from './svg-sales-budget';
import { SvgSave } from './svg-save';
import { SvgSaved } from './svg-saved';
import { SvgSearch } from './svg-search';
import { SvgSelectOption } from './svg-select-option';
import { SvgSend } from './svg-send';
import { SvgSettings } from './svg-settings';
import { SvgShare } from './svg-share';
import { SvgShared } from './svg-shared';
import { SvgSidebar } from './svg-sidebar';
import { SvgSidebarRightArrow } from './svg-sidebar-right-arrow';
import { SvgSignIn } from './svg-sign-in';
import { SvgSocialProfile } from './svg-social-profile';
import { SvgSort } from './svg-sort';
import { SvgStats } from './svg-stats';
import { SvgStore } from './svg-store';
import { SvgSuperman } from './svg-superman';
import { SvgTemplate } from './svg-template';
import { SvgThumbsDown } from './svg-thumbs-down';
import { SvgThumbsDownFilled } from './svg-thumbs-down-filled';
import { SvgThumbsUp } from './svg-thumbs-up';
import { SvgThumbsUpFilled } from './svg-thumbs-up-filled';
import { SvgTiktok } from './svg-tiktok';
import { SvgTiktokColor } from './svg-tiktok-color';
import { SvgTodo } from './svg-to-do';
import { SvgTraffic } from './svg-traffic';
import { SvgTrafficMenu } from './svg-traffic-menu';
import { SvgTrashBin } from './svg-trash-bin';
import { SvgUnHideEye } from './svg-unhide-eye';
import { SvgUpload } from './svg-upload';
import { SvgUrlLink } from './svg-url-link';
import { SvgUser } from './svg-user';
import { SvgUserInfluencerRemove } from './svg-user-influencer-remove';
import { SvgUserJoined } from './svg-user-joined';
import { SvgVerifiedUser } from './svg-verified-user';
import { SvgWarning } from './svg-warning';
import { SvgWave } from './svg-wave';
import { SvgYoutube } from './svg-youtube';
import { SvgZoom } from './svg-zoom';
import { SvgZoomIn } from './svg-zoom-in';
import { SvgZoomOut } from './svg-zoom-out';

export const SvgIconComponents: svgIconComponents = {
	// Communication
	message: SvgMessage,
	'message-filled': SvgMessageFilled,
	'message-empty': SvgMessageEmpty,
	mail: SvgMail,
	blast: SvgBlast,
	comment: SvgComment,
	'comment-filled': SvgCommentFilled,
	notification: SvgNotification,
	'private-message': SvgDirectMessage,
	'email-missing': SvgEmailMissing,

	// Photo and Image
	image: SvgImage,

	// Deprecated
	'chevron-left': SvgChevronLeft, // Please use arrow-* icon
	'chevron-right': SvgChevronRight, // Please use arrow-* icon
	'chevron-down': SvgChevronDown, // Please use arrow-* icon
	'chevron-up': SvgChevronUp, // Please use arrow-* icon

	//UI Actions
	'arrow-right': SvgArrowRight,
	'arrow-left': SvgArrowLeft,
	'arrow-down': SvgArrowDown,
	'arrow-up': SvgArrowUp,
	'circle-arrow-up': SvgCircleArrowUp,
	'circle-arrow-right': SvgCircleArrowRight,
	'circle-arrow-left': SvgCircleArrowLeft,
	'arrow-large-right': SvgArrowLargeRight,
	'arrow-large-left': SvgArrowLargeLeft,
	refresh: SvgRefresh,
	'chevron-left-double': SvgChevronLeftDouble,
	'chevron-right-double': SvgChevronRightDouble,

	drag: SvgDrag,
	options: SvgOptions,
	send: SvgSend,
	hide: SvgHideEye,
	unhide: SvgUnHideEye,
	share: SvgShare,
	download: SvgDownload,
	'cancel-circle': SvgCancelCircle,
	'add-circle': SvgAddCircle,
	upload: SvgUpload,
	copy: SvgCopy,
	'trash-bin': SvgTrashBin,
	expand: SvgExpand,
	save: SvgSave,
	saved: SvgSaved,
	'circle-cross': SvgCircleCross,
	plus: SvgPlus,
	minus: SvgMinus,
	cross: SvgCross,
	search: SvgSearch,
	filters: SvgFilters,
	sort: SvgSort,
	zoom: SvgZoom,
	pen: SvgPen,
	'platform-toggle': SvgPlatformToggle,
	'external-link': SvgExternalLink,
	'url-link': SvgUrlLink,

	'thumbs-down': SvgThumbsDown,
	'thumbs-up': SvgThumbsUp,
	'thumbs-down-filled': SvgThumbsDownFilled,
	'thumbs-up-filled': SvgThumbsUpFilled,
	like: SvgHeart,
	liked: SvgHeartLiked,

	// Social
	tiktok: SvgTiktok,
	'tiktok-color': SvgTiktokColor,
	instagram: SvgInstagram,
	'instagram-circle-color': SvgInstagramCircleColor,
	'instagram-color': SvgInstagramColor,
	youtube: SvgYoutube,

	// Campaign Activities
	archive: SvgArchive,
	product: SvgProduct,
	calendar: SvgCalendar,
	'campaign-brief': SvgCampaignBrief,
	celebrate: SvgCelebrate,
	coupon: SvgCoupon,
	'assignment-reel': SvgAssignmentReel,
	'assignment-post': SvgAssignmentPost,
	'assignment-story': SvgAssignmentStory,
	'affiliate-campaign': SvgAMCampaign,
	campaign: SvgCampaign,
	changes: SvgChanges,
	'new-message': SvgNewMessage,

	//Cropper Toolbar
	crop: SvgCrop,
	'flip-horizontal': SvgFlipHorizontal,
	'flip-vertical': SvgFlipVertical,
	'rotate-left': SvgRotateLeft,
	'rotate-right': SvgRotateRight,
	'zoom-out': SvgZoomOut,
	'zoom-in': SvgZoomIn,

	// Business Payment
	money: SvgMoney,
	'credit-card': SvgCreditCard,
	'add-new-folder': SvgAddToFolder,
	folder: SvgFolder,
	'pdf-file': SvgPdfFile,
	file: SvgFile,
	'other-file': SvgOtherFile,
	'add-document': SvgAddDocument,
	note: SvgNote,
	'dl-file-invoice': SvgDlFileInvoice,

	// Graphs
	activations: SvgDLActivations,
	traffic: SvgTraffic,
	engagement: SvgEngagement,
	'gross-reach': SvgGrossReach,
	content: SvgDLContent,
	cpr: SvgDLCpr,
	cpe: SvgDLCpe,
	conversions: SvgDLConversions,
	click: SvgClick,
	impressions: SvgImpressions,
	'sales-budget': SvgSalesBudget,
	audience: SvgAudience,
	reach: SvgReach,
	stats: SvgStats,
	'reach-rate': SvgDLReachRate,

	// Other
	template: SvgTemplate,
	superman: SvgSuperman,
	hourglass: SvghourGlass,
	settings: SvgSettings,
	'lock-checkmark': SvgLockCheckmark,
	lock: SvgLock,
	circle: SvgCircle,
	'look-a-like': SvgLookalike,
	brand: SvgBrand,
	checkmark: SvgCheckmark,
	'check-circle': SvgCheckCircle,
	'check-circle-filled': SvgCheckCircleFilled,
	sidebar: SvgSidebar,
	'sidebar-right-arrow': SvgSidebarRightArrow,
	move: SvgMoveCheck,

	list: SvgList,
	'list-add': SvgListAdd,
	'list-influencers': SvgListInfluencers,
	todo: SvgTodo,
	'dl-summary-content': SvgDLSummaryContent,

	influencer: SvgInfluencer,
	user: SvgUser,
	'user-influencer-remove': SvgUserInfluencerRemove,
	'dl-influencers': SvgDLInfluencers,
	'dl-vtr': SvgDLVtr,
	'add-account': SvgAddAccount,
	shared: SvgShared,
	'social-profile': SvgSocialProfile,
	'user-joined': SvgUserJoined,
	'verified-user': SvgVerifiedUser,
	'join-profile': SvgJoinProfile,

	help: SvgHelp,
	'info-circle': SvgInfoCircle,
	alert: SvgAlert,

	play: SvgPlay,

	'sign-in': SvgSignIn,
	'bank-id': SvgBankId,

	home: SvgHome,

	// Lists
	'folder-shared-with-me': SvgFolderSharedWithMe,
	'folder-shared': SvgFolderShared,
	'list-shared-with-me': SvgListSharedWithMe,
	'list-shared': SvgListShared,
	'item-shared': SvgItemShared,
	'item-shared-with-me': SvgItemSharedWithMe,
	all: SvgAll,

	store: SvgStore,
	collapse: SvgCollapse,
	'collapse-filled': SvgCollapseFilled,
	'mobile-check': SvgMobileCheck,
	'traffic-menu': SvgTrafficMenu,

	'ai-icon': SvgAi,
	pending: SvgPending,
	warning: SvgWarning,

	'automatic-payments': SvgAutomaticPayments,
	'manual-payments': SvgManualPayments,
	'select-option': SvgSelectOption,

	'change-segment': SvgChangeSegment,
	wave: SvgWave,
};
