import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { SummaryQuery } from 'api-queries';
import { convertFilterToQueryBuilder } from 'components/DataLibrary/AllRequests/convertFilterToQueryBuilder';
import { Heading } from 'components/Heading';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import MetricBar from 'components/MetricBar';
import Tooltip from 'components/Tooltip';
import { CALCULATION_FORMULA } from 'constants/data-library';
import useInjection from 'hooks/useInjection';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { formatNumber, formatPercent } from 'shared/helpers/Chart/chart-util';
import { formatAmount } from 'utils/formatters';
import { useAppSelector } from 'views//DataLibrary/hooks';
import { DashboardFilters, DashboardType } from 'views/DataLibrary/reducers/types';

import Styled from './Summary.style';

interface StatisticsSummary {
	influencers: number;
	followers: number;
	mediaObjects: number;
	assignments: number;
	platforms: string[];
	impressions: number;
	grossReach: number;
	estimatedUniqueReach: number;
	engagements: number;
	reachRate: number;
	engagementRate: number;
	actualEngagementRate: number;
	clicks: number;
	conversions: number;
	invoicedAmount: string;
	currency: string;
	costPerReach: number;
	viewThroughRate: number;
	clickThroughRate: number;
	costPerEngagement: number;
	uniqueInfluencers: number;
}

type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T][];
type Entry<T> = { [K in keyof T]: [K, T[K]] }[keyof T];

const updateInfluencersValue = (dashboard: DashboardType, data: Entries<StatisticsSummary>): Entries<StatisticsSummary> => {
	const summary = [...data];
	for (let i = 0; i < summary.length; i++) {
		if (summary[i][0] === 'influencers') {
			summary[i][1] = dashboard.uniqueInfluencers ?? summary[i][1];
			break;
		}
	}

	return summary;
};

/**
 * This component displays the summaryIcons without the toggle in the dashboard preview.
 * @returns {JSX.Element}
 */
// Add new type for ordering configuration
type SummaryItemKey = keyof StatisticsSummary;

const getTooltipContent = (item: string): string => {
	switch (item) {
		case 'influencers':
			return 'Total number of unique influencers in the campaign';
		case 'followers':
			return 'Total number of followers across all influencers';
		case 'platforms':
			return 'Number of social media platforms used in the campaign';
		case 'impressions':
			return 'Total number of times content was displayed';
		case 'grossReach':
			return 'Total reach across all content';
		case 'engagements':
			return CALCULATION_FORMULA.ENGAGEMENT_TOTAL;
		case 'actualEngagementRate':
			return CALCULATION_FORMULA.SUMMARY.ACTUAL_ENGAGEMENT_RATE;
		case 'assignments':
			return 'Total number of content pieces created';
		case 'clicks':
			return 'Total number of clicks on campaign content';
		case 'conversions':
			return 'Total number of conversions from the campaign';
		case 'costPerEngagement':
			return CALCULATION_FORMULA.CPE;
		case 'costPerReach':
			return 'Total cost divided by total reach';
		case 'viewThroughRate':
			return 'Percentage of users who watched the entire video content';
		case 'clickThroughRate':
			return CALCULATION_FORMULA.CTR;
		case 'invoicedAmount':
			return 'Total amount invoiced for the campaign';
		case 'reachRate':
			return CALCULATION_FORMULA.SUMMARY.REACH_RATE;
		default:
			return '';
	}
};

const Summary = (props: { inDataLibrary?: boolean }): JSX.Element => {
	const [paidDataIncluded, setPaidDataIncluded] = useState<boolean>(false);
	const [displayRealValue, setDisplayRealValue] = useState<boolean>(false);
	const [selectedValue, setSelectedValue] = useState<number | null>(null);
	const orderConfig = {
		order: [
			'influencers',
			'followers',
			'assignments',
			'grossReach',
			'reachRate',
			'engagements',
			'actualEngagementRate',
			'clicks',
			'clickThroughRate',
			'conversions',
			'costPerEngagement',
			'costPerReach',
			'invoicedAmount',
			'impressions',
			'platforms',
		],
		enabled: [
			'grossReach',
			'reachRate',
			'engagements',
			'costPerEngagement',
			'viewThroughRate',
			'actualEngagementRate',
			'clicks',
			'clickThroughRate',
			'impressions',
		],
	};

	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);

	const statisticsManager = useInjection<StatisticsManager>(StatisticsManager);
	// const dashboardManager = useInjection<DataLibraryManager>(DataLibraryManager);

	const filter = paidDataIncluded ? { ...dashboardFilter, paidMediaDashboardId: dashboard.id } : dashboardFilter;
	const qb = convertFilterToQueryBuilder<SummaryQuery>(filter);

	let summaryData: Entries<StatisticsSummary> | undefined;
	let isLoading = true;

	const sortSummaryData = (data: Entries<StatisticsSummary>): Entries<StatisticsSummary> => {
		return [...data].sort((a, b) => {
			const aIndex = orderConfig.order.indexOf(a[0] as SummaryItemKey);
			const bIndex = orderConfig.order.indexOf(b[0] as SummaryItemKey);
			return aIndex - bIndex;
		});
	};

	if (dashboard.id) {
		const { result, error } = statisticsManager.showSummary(qb);
		if (error) {
			console.error(error.message);
		}
		isLoading = !result;
		if (result) {
			const excludedItems = ['currency', 'mediaObjects', 'engagementRate', 'invoicedAmount'];
			const data = Object.entries(result.attributes) as Entries<StatisticsSummary>;
			let currency = null;
			let invoicedAmount = null;

			data?.forEach((summaryItem) => {
				if (summaryItem[0] === 'invoicedAmount') {
					invoicedAmount = summaryItem[1];
				} else if (summaryItem[0] === 'currency') {
					currency = summaryItem[1];
				}
			});

			const tmpData = data?.filter((summaryItem) => !excludedItems.includes(summaryItem[0]));
			if (invoicedAmount && currency) {
				tmpData.push(['invoicedAmount', formatAmount(invoicedAmount, currency)]);
			}

			summaryData = sortSummaryData(updateInfluencersValue(dashboard, tmpData));
		}
	}

	useEffect(() => {
		if (typeof summaryData !== 'undefined') {
			summaryData = updateInfluencersValue(dashboard, summaryData);
		}
	}, [dashboard.uniqueInfluencers]);

	const getIcon = (item: string): string => {
		switch (item) {
			case 'influencers':
				return 'dl-influencers';
			case 'followers':
				return 'audience';
			case 'platforms':
				return 'list';
			case 'impressions':
				return 'impressions';
			case 'grossReach':
				return 'gross-reach';
			case 'engagements':
				return 'engagement';
			case 'actualEngagementRate':
				return 'reach';
			case 'assignments':
				return 'dl-summary-content';
			case 'clicks':
				return 'click';
			case 'conversions':
				return 'conversions';
			case 'costPerEngagement':
				return 'cpe';
			case 'costPerReach':
				return 'cpr';
			case 'clickThroughRate':
				return 'click';
			case 'invoicedAmount':
				return 'file';
			case 'reachRate':
				return 'reach-rate';
			default:
				return '';
		}
	};
	const getTitle = (item: string): string => {
		switch (item) {
			case 'influencers':
				return 'Influencers';
			case 'followers':
				return 'Total followers';
			case 'platforms':
				return 'Platforms';
			case 'impressions':
				return 'Impressions';
			case 'grossReach':
				return 'Gross reach';
			case 'engagements':
				return 'Engagement';
			case 'actualEngagementRate':
				return 'Engagement rate';
			case 'assignments':
				return 'Activations';
			case 'clicks':
				return 'Clicks';
			case 'conversions':
				return 'Conversions';
			case 'costPerEngagement':
				return 'CPE';
			case 'costPerReach':
				return 'CPR';
			case 'viewThroughRate':
				return 'VTR';
			case 'clickThroughRate':
				return 'CTR';
			case 'invoicedAmount':
				return 'Invoiced amount';
			case 'reachRate':
				return 'Reach rate';
			default:
				return '';
		}
	};

	const shouldBeDisplayedInPercent = (title: string) => {
		switch (title) {
			case 'engagementRate':
				return true;
			case 'actualEngagementRate':
				return true;
			case 'reachRate':
				return true;
			case 'clickThroughRate':
				return true;
			case 'viewThroughRate':
				return true;
			default:
				return false;
		}
	};

	const getPlatformShortName = (platform: string): string => {
		switch (platform.toLowerCase()) {
			case 'instagram':
				return 'IG';
			case 'tiktok':
				return 'TT';
			default:
				return platform;
		}
	};

	const displayValue = (item: Entry<StatisticsSummary>, i: number) => {
		const title = item[0];
		const value = item[1];

		if (Array.isArray(value)) {
			return value?.length > 0 ? value?.length : 0;
		}
		if (shouldBeDisplayedInPercent(title) && Number(value) > 0) {
			return formatPercent(value);
		}
		return displayRealValue && i === selectedValue ? value : formatNumber(value, true);
	};

	const hideValues = (title: string) => {
		return !orderConfig.enabled.includes(title as SummaryItemKey);
	};

	// Helper function to get value from summary data
	const getValueFromSummary = (key: SummaryItemKey): string | number => {
		if (!summaryData) return '-';
		const item = summaryData.find(([k]) => k === key);
		if (!item) return '-';

		const value = item[1];
		if (key === 'platforms' && Array.isArray(value)) {
			return value?.length > 0 ? value.map((platform) => getPlatformShortName(platform)).join(', ') : '0';
		}
		if (Array.isArray(value)) {
			return value.length > 0 ? value.length : '-';
		}
		return formatNumber(value, true);
	};

	const metricBarItems = [
		{
			label: 'Influencers',
			value: getValueFromSummary('influencers'),
			tooltipText: 'Number of influencers in this campaign',
		},
		{
			label: 'Activations',
			value: getValueFromSummary('assignments'),
			tooltipText: 'Number of assignments in this campaign',
		},
		{
			label: 'Total followers',
			value: getValueFromSummary('followers'),
			tooltipText: 'Total number of followers',
		},
		{
			label: 'Networks',
			value: getValueFromSummary('platforms'),
			tooltipText: 'Networks in campaign',
		},
	];

	return (
		<Styled.SummaryContainer>
			<MetricBar items={metricBarItems} />
			<Styled.Wrapper className={classNames({ collapsed: false, inPublic: !props.inDataLibrary })}>
				<Styled.Header>
					<Styled.Title>Overview</Styled.Title>
					{dashboard?.hasPaidMedia && (
						<Styled.ToggleWrapper>
							<p>Paid media {paidDataIncluded ? 'included' : 'excluded'}</p>
							<Styled.ToggleSwitch>
								<input
									type='checkbox'
									className={classNames({ checked: paidDataIncluded })}
									checked={paidDataIncluded}
									onChange={() => setPaidDataIncluded(!paidDataIncluded)}
								/>
								<Styled.Slider className={classNames({ checked: paidDataIncluded })} />
							</Styled.ToggleSwitch>
						</Styled.ToggleWrapper>
					)}
				</Styled.Header>
				<Styled.Body>
					{typeof summaryData === 'undefined' || summaryData.length < 1 ? (
						<Styled.SpinnerWrapper>
							<LoadingSpinner />
						</Styled.SpinnerWrapper>
					) : (
						<Styled.SummaryContainerItemWrapper>
							{summaryData.map((item: Entry<StatisticsSummary>, i: number) => {
								const title = item[0];
								const value = item[1];
								const hasValue =
									(Array.isArray(value) && value?.length > 0) ||
									(!Array.isArray(value) && Number(value) > 0) ||
									(typeof value === 'string' && value !== '0' && value?.length > 0);

								if (hideValues(title) || !hasValue) {
									return;
								}

								return (
									<div key={i}>
										<Tooltip content={getTooltipContent(title)}>
											<Styled.SummaryContainerItem key={i}>
												<Styled.SummaryContainerItemBox>
													{isLoading ? (
														<LoadingSpinner size='sm' />
													) : (
														<div data-testid='item-box-icon-container'>
															<Icon name={getIcon(item[0])} />
														</div>
													)}
												</Styled.SummaryContainerItemBox>
												<Styled.SummaryContainerItemData>
													<Heading as='h4'>{getTitle(title)}</Heading>
													<div
														onMouseEnter={() => {
															setSelectedValue(i), setDisplayRealValue(true);
														}}
														onMouseLeave={() => {
															setSelectedValue(null), setDisplayRealValue(false);
														}}
													>
														<Heading as='h4' data-testid={`${title}-value`} className='thin'>
															{displayValue(item, i)}
														</Heading>
													</div>
												</Styled.SummaryContainerItemData>
											</Styled.SummaryContainerItem>
										</Tooltip>
									</div>
								);
							})}
						</Styled.SummaryContainerItemWrapper>
					)}
				</Styled.Body>
			</Styled.Wrapper>
		</Styled.SummaryContainer>
	);
};

export default Summary;
