import { AxiosError } from 'axios';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BrandModel, ClientInviteModel, ClientModel, ClientPrivilegeModel, PublisherModel, UserModel } from 'api-models';
import { ListClientsQuery } from 'api-queries';
import noResult from 'assets/icons/empty-state-no-result.svg';
import EmptyState from 'components/EmptyState/V2';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import { Heading } from 'components/Heading';
import Icon from 'components/Icon';
import BrandItemCard from 'components/Settings/Components/BrandItemCard/BrandItemCard';
import CenteredSpinner from 'components/Settings/Components/CenteredSpinner/CenteredSpinner';
import Styled from 'components/Settings/Components/ClientDetails/ClientDetails.style';
import CreateBrand from 'components/Settings/Components/CreateBrand/CreateBrand';
import DangerZone from 'components/Settings/Components/DangerZone/DangerZone';
import EditClientName from 'components/Settings/Components/EditClientName/EditClientName';
import Hr from 'components/Settings/Components/Hr/Hr';
import InvitationList from 'components/Settings/Components/InvitationList/InvitationList';
import InviteUsers from 'components/Settings/Components/InviteUsers/InviteUsers';
import LogoAndAdmins from 'components/Settings/Components/LogoAndAdmins/LogoAndAdmins';
import SubTitle from 'components/Settings/Components/SubTitle/SubTitle';
import UsersWithRolesList from 'components/Settings/Components/UsersWithRolesList/UsersWithRolesList';
import { OrganizationHierarchyRole } from 'components/Settings/types';
import { CREATE_BRAND, CREATE_INVITATION, DELETE, EDIT } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { pathSettingsClients } from 'routing/PathLookup';
import ClientManager from 'services/ApiManager/Client.manager';
import toast from 'services/Toast';
import Grid from 'styles/grid/grid';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

const ClientDetails = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');

	const navigate = useNavigate();
	const { clientId } = useParams();

	const manager = useInjection<ClientManager>(ClientManager);
	const queryBuilder = RequestQueryBuilder.create<ListClientsQuery>(['logo', 'edit', 'delete', 'createInvitation', 'createBrand', 'deleteLogo'])
		.withInclude('brands', ['logo', 'createInvitation', 'edit', 'delete'])
		.withInclude('publisher');
	const { repository: ClientsRepository, isLoading: isLoadingClients, mutate: mutateFn } = manager.listClients(queryBuilder);
	const client = clientId ? ClientsRepository.find<ClientModel>('client', clientId) : undefined;
	const publisher = client ? ClientsRepository.findOneByRelation<PublisherModel, ClientModel>(client, 'publisher') : undefined;

	const { repository, mutate } = manager.listUsersAndInvitations(clientId!);

	const invites: ClientInviteModel[] = repository.findAll<ClientInviteModel>('clientInvite');
	const userPrivileges: ClientPrivilegeModel[] = repository.findAll<ClientPrivilegeModel>('clientPrivilege');

	const { userCan } = useFeaturePermissions(client?.links);

	const getBrandsByClient = (client: ClientModel): BrandModel[] => {
		return repository.findByRelation<BrandModel, ClientModel>(client, 'brands');
	};

	function getUserFromClientPrivilege(privilege: ClientPrivilegeModel): UserModel {
		return repository.findOneByRelation<UserModel, ClientPrivilegeModel>(privilege, 'user') as UserModel;
	}

	const listAdmins = (): UserModel[] => {
		return userPrivileges
			.filter((privilege: ClientPrivilegeModel) => privilege.attributes.role === OrganizationHierarchyRole.ADMINISTRATOR)
			.map((privilege: ClientPrivilegeModel) => getUserFromClientPrivilege(privilege));
	};

	const matchesSearch = (search: string, brand: BrandModel): boolean => {
		const toLower = search.toLowerCase().trim();
		const brandName: string = brand.attributes.name.toLowerCase().trim();

		if ('' === toLower) {
			return true;
		}

		return brandName.includes(toLower);
	};

	const inviteUsersToClient = (emails: string[], role: OrganizationHierarchyRole): Promise<void> => {
		return manager.inviteUsers(client!.id, { role: role, emails: emails }).then(() => mutate());
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const changeUserRole = async (target: EventTarget & HTMLSelectElement, user: UserModel) => {
		target.disabled = true;
		setIsLoading(true);

		try {
			if (target.value === 'delete') {
				await manager.deleteUser(client!.id, user.id);
				toast.success('Member removed');
			} else {
				await manager.updateRole(client!.id, user.id, { role: target.value });
				toast.success('Role updated');
			}
			mutate();
		} catch (e) {
			if (target.value !== 'delete') {
				console.error(e);
				toast.error(getErrorMessageOnPost('changing the role'));
			}
		} finally {
			setIsLoading(false);
			target.disabled = false;
		}
	};

	const deleteInvite = (inviteId: string) => {
		manager
			.deleteInvite(client!.id, inviteId)
			.then(() => {
				toast.success('Invite deleted');
				mutate();
			})
			.catch((e: AxiosError) => {
				console.error(e);
				toast.error(getErrorMessageOnPost('deleting the invite'));
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const deleteImage = () => {
		client &&
			manager.deleteLogo(client.id).then(() => {
				mutateFn();
				toast.success('Logo deleted');
			});
	};

	const uploadLogo = (file: File) => {
		client &&
			manager.uploadLogo(client.id, file).then(() => {
				mutateFn();
				toast.success('Logo uploaded');
			});
	};

	useEffect(() => {
		if (!clientId) {
			navigate(pathSettingsClients());
		}
	}, [clientId]);

	return isLoadingClients || !client ? (
		<CenteredSpinner />
	) : (
		client && (
			<>
				<Styled.ButtonsWrapper>
					<EditClientName client={client} canEdit={userCan(EDIT)} publisher={publisher} />
					{userCan(DELETE) && (
						<DangerZone
							infoText={`Be aware, you can't regret deleting the client. Please, be sure`}
							disableDelete={getBrandsByClient(client).length > 0}
							deleteButtonText='Delete'
							deleteModalTitle={`Are you sure that you want to delete client ${client.attributes.name}?`}
							action={() => {
								setIsLoading(true);
								manager
									.delete(client?.id)
									.then(() => {
										navigate(pathSettingsClients());
									})
									.finally(() => {
										setIsLoading(false);
									});
							}}
							isLoading={isLoading}
						/>
					)}
				</Styled.ButtonsWrapper>
				<Grid.Container gap='16'>
					<Grid.Column xl={12}>
						<div style={{ width: '300px' }}>
							<LogoAndAdmins
								admins={listAdmins()}
								item={client}
								uploadFn={(file: File) => uploadLogo(file)}
								canEdit={userCan(EDIT)}
								deleteImageFn={deleteImage}
							/>
						</div>
					</Grid.Column>
					<Grid.Column xl={12}>
						<div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
							<Styled.StyledCard>
								<SubTitle text={`Who has access to ${client.attributes?.name}`}></SubTitle>
								<p>
									{userCan(CREATE_INVITATION)
										? `Add or manage who has access to all of ${client.attributes?.name}'s campaigns, including all of its associated brands.`
										: ''}
								</p>
								<>
									<p>
										{userCan(CREATE_INVITATION) ? `Share access to ${client.attributes?.name} by inviting individuals.` : null} Members with access can view and
										be assigned to all of <span>{client.attributes?.name}</span>'s campaigns.{' '}
										{userCan(CREATE_INVITATION) ? 'Permissions can be edited at any time.' : null}
									</p>
									<Heading as='h4'>Members</Heading>
									{userCan(CREATE_INVITATION) && (
										<InviteUsers
											availableRoles={(client?.attributes.availableRoles as OrganizationHierarchyRole[]) ?? []}
											inviteTitle={`Invite to ${client.attributes.name}`}
											buttonText='Invite member'
											drawerTitle={`Client management`}
											testId='invite-client-users'
											inviteFn={(emails, role) => inviteUsersToClient(emails, role)}
											companyName={client.attributes.name}
											roles={userPrivileges}
											invites={invites}
											deleteInvite={(inviteId: string) => deleteInvite(inviteId)}
											getUser={getUserFromClientPrivilege}
											changeUserRole={changeUserRole}
										/>
									)}
									<Styled.ListWrapper>
										<UsersWithRolesList
											availableRoles={(client?.attributes.availableRoles as OrganizationHierarchyRole[]) ?? []}
											roles={userPrivileges}
											changeUserRole={changeUserRole}
											getUser={getUserFromClientPrivilege}
											canEdit={userCan(CREATE_INVITATION)}
										/>
										<InvitationList invites={invites} deleteInvite={deleteInvite} canEdit={userCan(CREATE_INVITATION)} />
									</Styled.ListWrapper>
								</>
							</Styled.StyledCard>
						</div>
					</Grid.Column>
					<Grid.Column xl={12}>
						<Hr />
						<SubTitle text={`Brands associated with ${client.attributes?.name}`} />
						{userCan(CREATE_BRAND) && <p style={{ marginBottom: '40px' }}>You must add a brand before creating a campaign.</p>}
						{getBrandsByClient(client).length > 0 && (
							<Styled.SearchInputWrapper>
								<Field>
									<Input
										placeholder='Search for brands'
										value={searchValue}
										onChange={({ target }) => setSearchValue(target.value)}
										contentBefore={<Icon name='search' size='16' />}
									/>
								</Field>
							</Styled.SearchInputWrapper>
						)}
						{userCan(CREATE_BRAND) ? <CreateBrand client={client} mutateFn={mutateFn} publisher={publisher} /> : <div />}
						<Styled.BrandLogosWrapper className={classNames({ searching: searchValue.length > 0 })}>
							{getBrandsByClient(client)
								.sort((a: BrandModel, b: BrandModel) => a.attributes.name.toLowerCase().localeCompare(b.attributes.name.toLowerCase()))
								.map((brand, key) => {
									return (
										<div key={key}>
											<BrandItemCard brand={brand} client={client} hide={!matchesSearch(searchValue, brand)} />
										</div>
									);
								})}
							{getBrandsByClient(client).length > 0 && getBrandsByClient(client).filter((brand) => matchesSearch(searchValue, brand)).length === 0 && (
								<Styled.EmptyResultImgWrapper>
									<EmptyState
										image={noResult}
										altText='No result found'
										testId='no-result-found'
										text={
											<>
												<p>
													Unfortunately, <strong>'{searchValue}'</strong> did not return any results.
												</p>
												{userCan(CREATE_BRAND) ? <p>Check the spelling or add a new brand.</p> : <p>Check the spelling.</p>}
											</>
										}
									/>
								</Styled.EmptyResultImgWrapper>
							)}
						</Styled.BrandLogosWrapper>
					</Grid.Column>
				</Grid.Container>
			</>
		)
	);
};

export default ClientDetails;
