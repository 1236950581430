import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from 'styles/theme/colors';
import { breakpoints } from 'styles/variables/media-queries';
import { PALETTE } from 'styles/variables/original-colors';
/**
 * @todo remove !important after we have change everything regarding the typography
 */

const Wrapper = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 60px;
	background-color: ${({ theme }) => theme.colors.background};
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding-top: 0.5rem;
	margin: 0;
	z-index: ${({ theme }) => theme.zIndexes.header};
	transition:
		box-shadow 0.2s ease-in-out,
		background-color 0.2s ease-in-out;
`;

const HeaderTop = styled.div`
	display: flex;
	align-items: center;
	gap: 40px;
	padding: 0 1rem 1rem;
`;

const Logotype = styled(Link)`
	display: none;
	color: #333;
	font-size: 1.125rem;
	font-weight: 600;
	padding-top: 0.1875rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@media (min-width: ${breakpoints.md}) {
		display: block;
	}
`;

const Navigation = styled.nav`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const UserList = styled.ul`
	display: flex;
	align-items: center;
	padding: 0;
	margin: 0 -8px;
	list-style: none;
	margin-left: auto;
	flex-shrink: 0;

	a {
		border-bottom: none;
	}
`;
const UserListItem = styled.li`
	display: inline-block;
	margin: 0 4px;
	&.margin-left {
		margin-right: 24px;
		margin-left: 8px;
	}
`;

const ComponentContainer = styled.div`
	display: flex;
	flex-grow: 1;
	align-items: center;

	@media (min-width: ${breakpoints.md}) {
		justify-content: center;
	}
`;

const ActionButton = styled.button`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background-color: ${colors.mist};
	padding: 0.25rem;
	border-radius: 60px;
	width: 40px;
	height: 40px;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	font-weight: 900;
	z-index: 10;
	&:hover {
		background-color: ${PALETTE.reachGreen};
	}

	.icon {
		line-height: 0;
		&.push-down {
			margin-top: 2px;
		}
	}

	span {
		padding-top: 0.1875rem;
		font-size: 0.875rem;
		line-height: 0;
	}
`;

const notificationCircle = styled.div`
	position: absolute;
	top: 0;
	right: -4px;
	width: 12px;
	height: 12px;
	background-color: ${PALETTE.sunsetRed};
	border: 1px solid #fff;
	border-radius: 20px;
	transition: opacity 0.2s ease-in-out;
	opacity: 0;

	&.visible {
		opacity: 1;
	}
`;

const Styled = {
	Wrapper,
	HeaderTop,
	Navigation,
	UserList,
	UserListItem,
	ComponentContainer,
	Logotype,
	ActionButton,
	notificationCircle,
};

export default Styled;
